import React from 'react';
import { Alert, Grid, Typography, TableContainer, Table, TableHead, TableRow, Stack, Button,
  TableCell, TableBody, Paper, Checkbox } from '@mui/material';
import { InputField, DatePickerField, RadioGroupField } from '../../FormFields';
import * as Yup from 'yup';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import ComputerIcon from '@mui/icons-material/Computer';
import PaidIcon from '@mui/icons-material/Paid';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


const assetTypes ={
  'location': PlaceIcon,
  'vehicle': DirectionsCarFilledIcon,
  'digital': ComputerIcon,
  'account': PaidIcon
}

function createData(assetType, type, title, marketValue, status) {
  return { assetType, TypeIcon: assetTypes[type], title, marketValue, status };
}

const rows = [
  createData('Personal Residence', 'location', '2717 Irving Ave. S.Minneapolis, MN  55408', '750K', 'Mortgage'),
  createData('Company Vehicle', 'vehicle', '2021 GMC Sierra 1500', '30K', 'Fully Owned'),
  createData('Company Domain', 'digital', 'www.renovateUSA.com', '15K', 'Fully Owned'),
  createData('Personal Savings Account', 'account', 'US Bank savings account 697666556', '75K', 'Free'),
];

const alignText= 'left';

const View = function() {
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell> {/* checkbox */}
            <TableCell>Asset</TableCell>
            <TableCell align={alignText}>Type</TableCell>
            <TableCell align={alignText}>Title</TableCell>
            <TableCell align={alignText}>Market Value</TableCell>
            <TableCell align={alignText}>Asset Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Checkbox/></TableCell>
              <TableCell component="th" scope="row">
                {row.assetType}
              </TableCell>
              <TableCell align={alignText}>{<row.TypeIcon/>}</TableCell>
              <TableCell align={alignText}>{row.title}</TableCell>
              <TableCell align={alignText}>{row.marketValue}</TableCell>
              <TableCell align={alignText}>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      <Button sx={{m:1}}><LibraryAddIcon fontSize='medium' sx={{mx:1}}/>Add Asset</Button>
    </React.Fragment>
  );
};

const Footer = function() {
  return <div/>; //no space for footer, hide it
}

export default {
  caption: 'Your quote is being processed...',
  title: "To improve your offer, please confirm that you own these assets:",
  View,
  Footer,
  nextButtonText: 'Confirm'
};
