import * as React from 'react';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
  Draggable
} from 'react-beautiful-dnd';

import {
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    List,
    ListItemButton,
    Stack,
    Checkbox,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box
  } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


const DraggableListItem = ({ item, index }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem px={0} sx={{paddingLeft:0}}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        //   className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          <Stack direction="row" justifyContent={'space-between'} alignItems="center" width="100%">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox defaultChecked p={0} sx={{padding:0}}/>
              <img src={item.icon} height='32px' />
              <ListItemText primary={item.primary} />
            </Stack>
            <DragIndicatorIcon/>
          </Stack>
        </ListItem>
      )}
    </Draggable>
  );
};

const DraggableList = React.memo(({ items, onDragEnd }) => {
  let count = 0;
  const itemsWithId = items.map(item => {
    item.id = (count++).toString();
    return item;
  });

  return (
    <Box height="100px">
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} >
            {itemsWithId.map((item, index) => (
              <DraggableListItem item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    </Box>
  );
});

export default DraggableList;
