import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@mui/material';

function RadioGroupField(props) {
  const { label, options, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup {...field} value={selectedValue ? selectedValue : ''}>
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio color='secondary'/>}
            label={item.label}
          />
        ))}
      </RadioGroup>
      {_renderHelperText()}
    </FormControl>
  );
}

RadioGroupField.defaultProps = {
  options: []
};

RadioGroupField.propTypes = {
  options: PropTypes.array.isRequired
};

export default RadioGroupField;
