import React from "react";
import {
  Typography,
  Stack,
  Button,    
  Grid,
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  Checkbox,
  Box
} from "@mui/material";

import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import GroupedList from "../GroupedList";

import TransferList from './TransferList';
import SortableList from './SortableList';

import {services} from '../services'


function ApplicationTitle(props) {
  return (
      <Typography variant="h6">
        {props.name}
      </Typography>
  );
}

const providers = services.map(service => { return {primary: service.name, icon:`/logos/${service.logo}`}});

function EditRequirement(props) {
  const {defaultFields, defaultNonRequiredFields} = props;
  const [autoValidate, setAutoValidate] = React.useState(true);

  const handleChangeAutoValidate = (event) => {
    setAutoValidate(event.target.checked);
  };

  return (
      <Stack spacing={2} m={2}>
        {defaultFields &&
        <TransferList left={defaultFields} right={defaultNonRequiredFields}/>}

        {!defaultFields &&
        <Grid container alignItems={'center'}>
          <Grid item xs={1}>
            <Checkbox defaultChecked/>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='body1'>Require Original Documents</Typography>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='body2' color='textSecondary'>Lama AI can fill this requirement using 1st and 3rd-party data providers. Choose this option if a copy of the original document is required, regardless of having an alternative source.</Typography>
          </Grid>
        </Grid>}

        <Grid container alignItems={'center'}>
          <Grid item xs={1}>
            <Checkbox checked={autoValidate} onChange={handleChangeAutoValidate}/>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='body1'>Lama AI Automatic Validation</Typography>
          </Grid>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={11}>
            <Typography variant='body2' color='textSecondary'>Utilize Lama's dynamic validation workflows, balancing cost and accuracy across data providers. Uncheck this option to manually select and rank the desired data providers.</Typography>
          </Grid>
          {!autoValidate && (
            <Grid item xs={1}>
            </Grid>
          )}
          {!autoValidate && (
            <Grid item xs={11}>
              <SortableList items={providers} onDragEnd={()=>console.log('drag end')}/>
            </Grid>
          )}
        </Grid>
      </Stack>
  );
}


export default function Integrations(props) {
  const requirementToStandard = (requirement) => {
    if(requirement?.name) return requirement;
    return {name: requirement}
  }

  const requirementsGroups = props.requirementsGroups.map((group) => {
    const standard = requirementToStandard()
    return {
      name: group.name, 
      items: group.requirements.map(requirement => ({...requirementToStandard(requirement), ItemTitle: ApplicationTitle}))
  }});

  return (
    <Stack height= "100%" spacing={2}>
      <Box textAlign={'right'}>
        <Button startIcon={<UploadFileIcon/>} variant="outlined">Auto-generate using form</Button>
      </Box>

      <GroupedList groups={Object.values(requirementsGroups)} EditView={EditRequirement} newButton/>
    </Stack>
  );
}
