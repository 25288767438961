import React from "react";
import {
  Card,
  Typography,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  Chip
} from "@mui/material";

const statusToColor = {
  Pending: "warning",
  Done: "success",
  Problem: "error",
  Review: "info",
};

const tableContent = [
  {category: "Assets", name: "Property Proof of Ownership", status: "Pending"},
  {category: "Ownership", name: "Missing Business Owners", status: "Pending"},
  {category: "Accounting", name: "Connect Quickbooks", status: "Pending"},
]

const Row = ({ category, name, status }) => (
  <TableRow
    key={name}
    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  >
    <TableCell component="th" scope="row">
      <Typography variant="body1">{category}</Typography>
    </TableCell>
    <TableCell component="th" scope="row">
      <Typography variant="body1">{name}</Typography>
    </TableCell>
    <TableCell>
      <Chip variant="outlined" color={statusToColor[status]} label={status} />
    </TableCell>
  </TableRow>
);

export const TaskList = () => (
  <Card sx={{ p: 6, height: "100%", borderRadius: "15px" }} elevation={0}>
    <Stack spacing={4}>
      <Stack direction={"row"} spacing={3} alignItems={"center"}>
        <Typography variant="h4" color="textSecondary">
          {"Open Tasks"}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {`last updated - ${new Date(
            Date.now() - 1 * 60 * 60
          ).toLocaleString()}`}
        </Typography>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>{"Category"}</b></TableCell>
              <TableCell><b>{"Name"}</b></TableCell>
              <TableCell><b>{"Status"}</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableContent.map((row) => (
              <Row {...row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  </Card>
);
