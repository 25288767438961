import {SvgIcon } from '@mui/material';

import { ReactComponent as ShopifySvg } from '../../assets/icons/shopify.svg';
import { ReactComponent as QuickboosSvg } from '../../assets/icons/quickbooks.svg';
import { ReactComponent as ExperianSvg } from '../../assets/icons/experian.svg';
import { ReactComponent as FreshbooksSvg } from '../../assets/icons/freshbooks.svg';
import { ReactComponent as LexisNexisSvg } from '../../assets/icons/lexis-nexis.svg';
import { ReactComponent as StripeSvg } from '../../assets/icons/stripe.svg';
import { ReactComponent as PaypalSvg } from '../../assets/icons/paypal.svg';
import { ReactComponent as AccountingSvg } from '../../assets/icons/accounting.svg';
import { ReactComponent as BankSvg } from '../../assets/icons/bank.svg';
import { ReactComponent as CreditBureauSvg } from '../../assets/icons/credit-bureau.svg';
import { ReactComponent as PayrollSvg } from '../../assets/icons/payroll.svg';
import { ReactComponent as PoliceSvg } from '../../assets/icons/police.svg';
import { ReactComponent as PosSvg } from '../../assets/icons/pos.svg';
import { ReactComponent as SocialMediaSvg } from '../../assets/icons/social-media.svg';
import { ReactComponent as StateRegistrySvg } from '../../assets/icons/state-registry.svg';
import { ReactComponent as TaxSvg } from '../../assets/icons/tax.svg';
import { ReactComponent as WebSvg } from '../../assets/icons/web.svg';
import { ReactComponent as InidicationRedSvg } from '../../assets/icons/indication-red.svg';
import { ReactComponent as InidicationGreenSvg } from '../../assets/icons/indication-green.svg';
import { ReactComponent as InidicationOrangeSvg } from '../../assets/icons/indication-orange.svg';
import { ReactComponent as InidicationGraySvg } from '../../assets/icons/indication-gray.svg';

export const ShopifyIcon = <SvgIcon component={ShopifySvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />;
export const QuickbooksIcon = <SvgIcon component={QuickboosSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const ExperianIcon = <SvgIcon component={ExperianSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const FreshbooksIcon = <SvgIcon component={FreshbooksSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const LexisNexisIcon = <SvgIcon component={LexisNexisSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const StripeIcon = <SvgIcon component={StripeSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const PaypalIcon = <SvgIcon component={PaypalSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const AccountingIcon = <SvgIcon component={AccountingSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />;
export const BankIcon = <SvgIcon component={BankSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const CreditBureauIcon = <SvgIcon component={CreditBureauSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const PayrollIcon = <SvgIcon component={PayrollSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const PoliceIcon = <SvgIcon component={PoliceSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const PosIcon = <SvgIcon component={PosSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const SocialMediaIcon = <SvgIcon component={SocialMediaSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const StateRegistryIcon = <SvgIcon component={StateRegistrySvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const TaxIcon = <SvgIcon component={TaxSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const WebIcon = <SvgIcon component={WebSvg} sx={{ fontSize:24, overflow:'visible', verticalAlign:'sub'}} />
export const IndicationRedIcon = <SvgIcon component={InidicationRedSvg} sx={{ fontSize:24, color:'#EB4970', verticalAlign:'top' }} />
export const IndicationGreenIcon = <SvgIcon component={InidicationGreenSvg} sx={{ fontSize:24, color:'#75ED9E', verticalAlign:'top'}} />
export const IndicationOrangeIcon = <SvgIcon component={InidicationOrangeSvg} sx={{ fontSize:24, color:'#FF9D4D', verticalAlign:'top'}} />
export const IndicationGrayIcon = <SvgIcon component={InidicationGraySvg} sx={{ fontSize:24, color:'#ACA2A4', verticalAlign:'top'}} />