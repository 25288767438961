import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

import {
  Chip,
  Typography,
  Stack,
  Avatar,
  Collapse,
  IconButton,
  Box,
  Card,
  Alert,
  Tooltip,
} from "@mui/material";

import {
  IndicationRedIcon,
  IndicationGreenIcon,
  IndicationOrangeIcon,
  IndicationGrayIcon,
} from "../CustomIcons";

import {
  values,
  createRow,
  collectSource,
  profitabilityAnalysis,
  liquidityAnalysis,
  operationalEfficiency,
  debtAnalysis,
  general,
  taxationAndLegal,
  web,
  social,
  local,
  owners,
} from "../data";

const statusToColor = {
  Pending: "warning",
  Done: "success",
  Problem: "error",
};

const getValidationIcon = function (validations) {
  if (validations && validations.length > 0) {
    var level = Math.max(
      ...validations.map((validation) => validation.severity)
    );
    let severity = "success";
    let Icon = IndicationGrayIcon;

    if (level === 0) {
      severity = "success";
      Icon = IndicationGreenIcon;
    } else if (level === 1) {
      severity = "warning";
      Icon = IndicationGrayIcon;
    } else if (level === 2) {
      severity = "warning";
      Icon = IndicationGrayIcon;
    } else if (level === 3) {
      severity = "warning";
      Icon = IndicationOrangeIcon;
    } else if (level >= 4) {
      severity = "error";
      Icon = IndicationRedIcon;
    }

    return (
      <Tooltip
        title={
          <Alert severity={severity}>
            {validations.map((validation) => validation.label).join(", ")}
          </Alert>
        }
      >
        {Icon}
      </Tooltip>
    );
  } else return <div />; //<CheckCircleOutlineIcon color='success'/>;
};

const reviewers = {
  colin: {
    name: "Colin Wilson",
    title: "Deal Team",
    avatarUrl: "/images/Colin.png",
  },
  alexis: {
    name: "Alexis Levi",
    title: "Complience Manager",
    avatarUrl: "/images/Alexis.png",
  },
};

export const CreateTable = (props) => {
  const alignText = "left";

  return (
    <Card sx={{ height: "100%" }} elevation={0}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align={alignText}></TableCell> {/*validations column*/}
              <TableCell align={alignText}>Indicator</TableCell>
              <TableCell align={alignText}>Value</TableCell>
              <TableCell align={alignText}>Trends</TableCell>
              <TableCell align={alignText}>Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow
                key={row.title}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align={alignText}>
                  {getValidationIcon(row.validations)}
                </TableCell>
                <TableCell>
                  <Tooltip title={row.attribute}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "180px",
                      }}
                    >
                      {row.attribute}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align={alignText}>
                  <Typography sx={{ maxWidth: "720px" }}>
                    {row.value}
                  </Typography>
                </TableCell>
                <TableCell align={alignText}>
                  {row.details && row.details.Icon && (
                    <StackedBarChartIcon sx={{ color: "primary.main" }} />
                  )}
                </TableCell>
                <TableCell align={alignText}>
                  {row.sources.map(
                    (source) =>
                      source.Icon && <span key={row.label}>{source.Icon}</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const Row = ({ name, collect, review, reviewer, innerTable }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        key={name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Typography variant="body1">{name}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            variant="outlined"
            color={statusToColor[collect]}
            label={collect}
          />
        </TableCell>
        <TableCell>
          {reviewer ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ width: "32px", height: "32px" }}>
                <img
                  src={reviewers[reviewer].avatarUrl}
                  width="32px"
                  height="32px"
                />
              </Avatar>
              <Stack>
                <Typography variant="body2" fontSize="13px">
                  {reviewers[reviewer].name}
                </Typography>
                <Typography variant="body2" fontSize="10px">
                  {reviewers[reviewer].title}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Chip
              variant="outlined"
              color={statusToColor[review]}
              label={review}
            />
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ paddingLeft: 3 }}
          >
            {innerTable}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const InformationTable = () => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Collect</TableCell>
            <TableCell>Review</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            name="General"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={general}></CreateTable>}
          />
          <Row
            name="Revenue"
            collect="Pending"
            reviewer="colin"
            innerTable={
              <CreateTable rows={profitabilityAnalysis}></CreateTable>
            }
          />
          <Row
            name="Bank, Liquidity, Cashflow"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={liquidityAnalysis}></CreateTable>}
          />
          <Row
            name="Operations and Growth"
            collect="Done"
            reviewer="alexis"
            innerTable={
              <CreateTable rows={operationalEfficiency}></CreateTable>
            }
          />
          <Row
            name="Debt"
            collect="Problem"
            review="Problem"
            innerTable={<CreateTable rows={debtAnalysis}></CreateTable>}
          />
          <Row
            name="Taxation"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={taxationAndLegal}></CreateTable>}
          />
          <Row
            name="Online Activity"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={web}></CreateTable>}
          />
          <Row
            name="Local Activity"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={local}></CreateTable>}
          />
          <Row
            name="Social Media"
            collect="Done"
            review="Done"
            innerTable={<CreateTable rows={social}></CreateTable>}
          />

          <Row
            name="KYC/B"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Accounting"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Expenses"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Ownership"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Personal Financials"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Assets"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Legal History"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Business Plan"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Employees"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Insurance"
            collect="Done"
            review="Done" 
          />
          <Row
            name="Market Trends, Competition"
            collect="Done"
            review="Done" 
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
