import React from "react";
import {
  Grid,
  Card,
  Typography,
  Stack,
  Box,
  Button} from "@mui/material";

import AutoModeIcon from '@mui/icons-material/AutoMode';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";

const productTypes = [
  {name: 'Exchange Hosted', description: 'Instantly serve your customers with a fully automated credit product. Lama AI and its network lenders take care of origination, compliance, and capital.', Icon: AutoModeIcon, link:'/products/add-product/exchange-hosted/select'},
  {name: 'AI Model', description: "Utilize Lama AI's seamless borrower onboarding and customizable decisioning engine, with the most valuable 1st & 3rd-party data enrichments and models.", Icon: SettingsSuggestOutlinedIcon},
  {name: 'Custom', description: "Configure a product that best fits your organization's needs. Harness the full power of the Lama AI's platform and network, integrated into your processes and systems.", Icon: TuneOutlinedIcon},
];

export default function AddProduct() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box p={4} width="100%">
      <Card sx={{ height: "100%", borderRadius: "15px" }} elevation={0} m={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={3}>
          <Typography variant="h5">
            Select the type of product you'd like to add 
          </Typography>
      </Stack>

      
      <Grid container spacing={2} p={3}>
        {productTypes.map((product, index) => (
          <Grid item xs={12/productTypes.length} >
          <Card elevation={0} m={4} key={index}  width="120px"
                sx={{ height: "100%", borderRadius: "4px", border: 1, borderColor: 'grey.300', cursor: product.link ? 'pointer' : 'default' }}
                onClick={() => {product.link && navigate(product.link)} }>
            <Stack spacing={3} m={4} alignItems="center">
              <Stack bgcolor={'primary.light'} width="132px" height="132px" alignItems={'center'} justifyContent={'center'} borderRadius={100}>
                {<product.Icon sx={{fontSize: 80, stroke: "#E6E0FF", strokeWidth: 1.2 }}/>}
              </Stack>
              <Stack spacing={1} alignItems="center">
                <Typography variant="h6" fontFamily={"Ubuntu"}>{product.name}</Typography>
                <Typography variant="body2"  fontFamily={"Ubuntu"} color="text.secondary" textAlign={'center'}>
                  {product.description}
                </Typography>
              </Stack>
              <Button variant='contained' endIcon={<ArrowForwardIcon/>} fullWidth
                onClick={() => product.link && navigate(product.link)}>
                Add
              </Button>
            </Stack>
          </Card>
          </Grid>
        ))}
      </Grid>
      </Card>
    </Box>
  );
}
