import React from "react";
import {
  Stack
} from "@mui/material";
import {DocumentGathering} from './DocumentGathering';
import { TaskList } from "./TaskList";

export const ApplicationTab = () => {
  return (
    <Stack spacing={3}>
        <DocumentGathering />
        <TaskList />
    </Stack>
  );
};