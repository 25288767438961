import React from "react";
import {
  Grid,
  Card,
  Typography,
  Stack,
  Box,
  Button,
  Divider
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';


function Attribute(props) {
  return (
    <Stack direction="row" alignItems="center" borderRadius={2} bgcolor={props.color} p={1} spacing={1}>
      {props.icon ? <props.icon style={{ color: 'gray' }} /> : null}
      <Typography variant="body2" color="text.secondary">{props.name}</Typography>
    </Stack>
  )
}

function Rule(props) {
  return ( <Stack direction="row" spacing={2} alignItems="center">
    {props.rules.map((innerRule, index) => {
      return <Stack direction="row" spacing={2} alignItems="center">
        <Attribute color="#FADDC3" {...innerRule.attribute}/>
        <Attribute color="#B0DBFA" name={innerRule.operator}/>
        <Attribute color="#E6E0FF" name={innerRule.value+(innerRule.attribute.suffix || '')}/>
        {index < props.rules.length - 1 && <Typography>OR</Typography>}
      </Stack>
    })}
  </Stack>
  )
}

export default function AutomationRules(props) {

  return (
    <Stack spacing={4}>
    <Box textAlign="right">
      <Button startIcon={<AddIcon/>} variant="outlined">New</Button>
    </Box>
    {props.rules.map((rule, index) => (
      <Stack spacing={1}>
        {console.log("auto-rule: " + JSON.stringify(rule))} 
        <Typography variant="h6">{rule.name}</Typography>
        <Card sx={{ height: "100%", borderRadius: "5px", p:2 }} elevation={0}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={8}>
              <Stack spacing={1}>
                {rule.rules.map((innerRules, index) => (
                  <Rule rules={innerRules} />
                  
                ))}
              </Stack>
            </Grid>
            <Button>
              <EditIcon/>
            </Button>
            <Grid item xs={10}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Typography><b>Action:</b> {rule.assign}</Typography>
            </Grid>
            {rule.sendTo && <Grid item xs={12}>
              <Typography><b>Send to:</b> {rule.sendTo}</Typography>
            </Grid>}
          </Grid>
        </Card>
      </Stack>
    ))}
    </Stack>        
  );
}
