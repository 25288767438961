import React from "react";
import {
  Typography,
  Stack,
  Grid,
  Link,
  Alert,
  TextField
} from "@mui/material";

import {services} from '../services'

import GroupedList from "../GroupedList";

function IntegrationTitle(props) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <img src={`/logos/${props.logo}`} height='64px' style={{marginTop:'16px'}} /> {/* marginTop is a patch that works for most of these icons */}
      <Stack direction="column" alignItems={'center'}>
        <Typography variant="h6">
          {props.name}
        </Typography>
        {/* <Typography variant="body2">
          lorem ipsum
        </Typography> */}
      </Stack>
    </Stack>
  );
};

function EditIntegration(props) {
  const { name } = props;
  return (
    <Stack spacing={2}>
      <Alert severity="info">
        <div>Integrate {name} and collect information on borrowers, verify declarations, and retrieve risk scores for underwriting.</div>
      <Link>Read more &gt;</Link>
      </Alert>

      <TextField label="Username"/>

      <TextField label="API Key"/>

    </Stack>
  );
};

const servicesByCategory = services.reduce((groups, service) => {
  service.categories.forEach(category => {
    if (!groups[category]) {
      groups[category] = {
        name: category,
        items: [],

      };
    };
    groups[category].items.push({...service, ItemTitle:IntegrationTitle});
  });
  return groups;
}, {});


export default function Integrations(props) {
  return (
    <Stack>
      <GroupedList groups={Object.values(servicesByCategory)} EditView={EditIntegration}/>
    </Stack>
  );
}
