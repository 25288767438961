import React from "react";
import {
  Card,
  Typography,
  Stack,
  Box,
  Button,
} from "@mui/material";

import LinesEllipsis from 'react-lines-ellipsis'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';


import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";
import { ReactComponent as ProductIcon } from '../../assets/icons/product.svg';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange.svg';


const products = [
  {name: 'Business Term Loan', link:'/products/business-term-loan', description: 'Business Term Loans are a type of financing that provides a lump sum of money to a business that is repaid in fixed monthly payments over a set period of time. Business Term Loans are typically used to finance large purchases, such as equipment, vehicles, or real estate. Business Term Loans are available for up to $5 million, and can be used for a variety of business purposes, including working capital, equipment, and real estate.'},
  {name: 'SBA 7(a)', description: 'SBA 7(a) loans are the most common type of SBA loan. They are available to businesses that are unable to obtain financing on reasonable terms through conventional lending channels. SBA 7(a) loans are available for a variety of business purposes, including working capital, equipment, and real estate. SBA 7(a) loans are available for up to $5 million, and can be used for a variety of business purposes, including working capital, equipment, and real estate.'},
  {name: 'Line of Credit'},
  {name: 'CRE'},
  {name: 'SBA 504', exchange: true, description: 'SBA 504 loans are available to businesses that are unable to obtain financing on reasonable terms through conventional lending channels. SBA 504 loans are available for a variety of business purposes, including working capital, equipment, and real estate. SBA 504 loans are available for up to $5 million, and can be used for a variety of business purposes, including working capital, equipment, and real estate.'},
  {name: 'Working Capital', exchange: true}, 
  {name: 'Equipment Financing', exchange: true, description: 'Equipment Financing is a type of financing that provides a lump sum of money to a business that is repaid in fixed monthly payments over a set period of time. Equipment Financing is typically used to finance large purchases, such as equipment, vehicles, or real estate. Equipment Financing is available for up to $5 million, and can be used for a variety of business purposes, including working capital, equipment, and real estate.'},
];

export default function SelectExchangeHostedProduct() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box width={'100%'}  m={5} sx={{borderRadius:'15px'}}>
    <Card >
      <Stack spacing={4} p={4}>
      <Typography variant="h6">
        Choose the type of credit product that you'd like to offer your customers
      </Typography>

      <Stack spacing={2}>
        {products.map((product, index) => (
          <Card elevation={0} m={4} key={index}
                sx={{ height: "100%", borderRadius: "15px", border: 1, borderColor: 'grey.300' }}>
            <Stack container justifyContent="space-between" direction="row" spacing={1} m={2} alignItems="center" >
              <Stack direction="row" alignItems={'center'} spacing={2} >
                <ExchangeIcon />
                {/* <Stack direction="row" alignItems={'center'} spacing={2}>  */}
                {/* <Stack direction="column"> */}
                  <Typography variant="h6" whiteSpace={'nowrap'}>{product.name}</Typography>
                  <Typography variant="body2" color="grey.500" >
                    <LinesEllipsis maxLine='1' text={product.description?.substring(0,80)} />
                  </Typography> 
                {/* </Stack> */}
              </Stack>
              <Button variant='contained' endIcon={<ArrowForwardIcon/>}
                onClick={() => navigate('/products/add-product/exchange-hosted/configure')}>
                Select
              </Button>
            </Stack>
          </Card>
        ))}
      </Stack>
      </Stack>
      </Card>
    </Box>
  );
}
