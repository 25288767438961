import React from "react";
import {
  Card,
  Typography,
  Stack,
  Switch,
  Box,
  TextField,
  Slider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { AutomaticDocumentGatheringIcon } from "./AutomaticDocumentGatheringIcon";

const Channels = () => {
  const marks = [
    {
      value: 0,
      label: "Phone only",
    },
    {
      value: 50,
      label: "Email only",
    },
    {
      value: 100,
      label: "Both",
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography variant="h6" color="textSecondary">
        {"Channels"}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {"Select the appropriate channels for customer communication"}
      </Typography>
      <Box pt={2} px={5}>
        <Slider step={null} defaultValue={0} marks={marks} color={"primary"} />
      </Box>
    </Stack>
  );
};

const Urgency = () => {
  const marks = [
    {
      value: 0,
      label: "Low",
    },
    {
      value: 12.5,
    },
    {
      value: 25,
    },
    {
      value: 37.5,
    },
    {
      value: 50,
    },
    {
      value: 62.5,
    },
    {
      value: 75,
    },
    {
      value: 87.5,
    },
    {
      value: 100,
      label: "High",
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography variant="h6" color="textSecondary">
        {"Urgency"}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {"This will determine frequency of communication with the customer"}
      </Typography>
      <Box pt={2} px={5}>
        <Slider
          step={12.5}
          defaultValue={25}
          marks={marks}
          color={"secondary"}
        />
      </Box>
    </Stack>
  );
};

const SenderDetails = () => (
  <Stack spacing={2}>
    <Typography variant="h6" color="textSecondary">
      {"Sender"}
    </Typography>
    <Typography variant="caption" color="textSecondary">
      {"Assign a banker to this application. All communication will be sent from this banker"}
    </Typography>
    <Stack direction={"row"} alignItems={"center"} spacing={5}>
      <Box
        alt="logo"
        component="img"
        sx={{ width: "64px", height: "64px" }}
        src="Avatar.png"
      />
      <Stack spacing={1}>
        <Typography variant="body2" color="textSecondary">
          {"Edit photo"}
        </Typography>
        <Stack spacing={2} direction={"row"}>
          <Typography variant="body2" color="textSecondary">
            {"Delete"}
          </Typography>
          <Typography variant="body2" color={"InfoText"}>
            {"Update"}
          </Typography>
        </Stack>
      </Stack>
      <TextField label="Name" variant="outlined" defaultValue={"John Doe"} fullWidth/>
      <TextField label="Email" variant="outlined" defaultValue={"Joed@gmail.com"} fullWidth/>
    </Stack>
  </Stack>
);

const CommunicationDays = () => {
  const days = [
    { name: "Monday", value: true },
    { name: "Tuesday", value: true },
    { name: "Wednesday", value: true },
    { name: "Thursday", value: true },
    { name: "Friday", value: false },
    { name: "Saturday", value: false },
    { name: "Sunday", value: false },
  ];

  return (
    <Stack spacing={1}>
      <Typography variant="h6" color="textSecondary">
        {"Communication Availability"}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {"Customer will be contacted on the selected days"}
      </Typography>
      <Stack direction={"row"} spacing={1} pt={2}>
        {days.map((day) => (
          <FormControlLabel
            label={day.name}
            control={<Checkbox checked={day.value} />}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const DocumentGathering = () => (
  <Card sx={{ p: 6, height: "100%", borderRadius: "15px" }} elevation={0}>
    <Stack spacing={4}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} spacing={2}>
          <AutomaticDocumentGatheringIcon />
          <Typography variant="h4" color="textSecondary">
            {"Document Gathering Automation"}
          </Typography>
        </Stack>
        <Switch color={"primary"} defaultChecked />
      </Stack>
      <SenderDetails />
      <Stack direction={"row"} spacing={10}>
        <Channels />
        <Urgency />
      </Stack>
      <CommunicationDays />
    </Stack>
  </Card>
);
