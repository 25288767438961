import * as React from 'react';
import {
    Grid,
    Typography,
    Stack,
    Dialog,
    Switch,
    Select,
    MenuItem,
    TextField
  } from "@mui/material";

import AltRouteIcon from '@mui/icons-material/AltRouteOutlined';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { ReactComponent as DontSendToNetworkIcon } from '../../../assets/icons/dont-send-to-network.svg';

const knockoutTargets = {
  reject: {name: 'Not Eligible', icon: DontSendToNetworkIcon},
  sendToNetwork: {name: 'Send to Network', icon: DoNotDisturbIcon},
  alternativeProduct: {name: 'Suggest Alternative', icon: AltRouteIcon},
}

function Attribute(props) {
  return (
    <Stack direction="row" alignItems="center" borderRadius={'4px'} bgcolor={props.color} py={'4px'} px={1} spacing={1}>
      {props.icon ? <props.icon style={{ color: 'gray' }} /> : null}
      <Typography variant="body2" color="text.secondary">{props.name}</Typography>
    </Stack>
  )
}

function SelectValue(props) {
  const {rule, selectedValue, setSelectedValue} = props;
  return (        
    <Select value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)} fullWidth>
      {rule.attribute.options.map((option, index) => {
        return <MenuItem key={index} value={option}>{option}{(rule.attribute.suffix || '')}</MenuItem>
      })}
    </Select>
  )
}

function NumberValue(props) {
  const {rule, selectedValue, setSelectedValue} = props;
  return (        
    <Stack direction="row" spacing={2} alignItems='center'>
      <TextField inputProps={{ type:'number', max:rule.attribute?.max }} 
      value={selectedValue} onChange={(event) => setSelectedValue(event.target.value)} />
      {rule.attribute.suffix && 
      <Typography variant="body2" color="text.secondary" >{rule.attribute.suffix}</Typography>}
    </Stack>
  )
}

export default function EditRule(props) {
  const { onClose, rule, open } = props;

  if (!rule) {
    return null;
  }

  const [selectedValue, setSelectedValue] = React.useState(rule.value);

  const handleClose = () => {
    rule.value = selectedValue;
    onClose(rule);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={4} py={2}>
        <Typography variant="h5">Edit Rule</Typography>
        <Switch defaultChecked />
      </Stack>
      <Grid container p={4} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1">Property</Typography>
        </Grid>
        <Grid item xs={6}>
        <Attribute color="#FADDC3" {...rule.attribute}/>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Operator</Typography>
        </Grid>
        <Grid item xs={6}>
        <Attribute color="#B0DBFA" name={rule.operator}/>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Value</Typography>
        </Grid>
        <Grid item xs={6}>
          {rule.attribute.options ? 
            <SelectValue rule={rule} selectedValue={selectedValue} setSelectedValue={setSelectedValue} /> : 
            rule.attribute.max ?
            <NumberValue rule={rule} selectedValue={selectedValue} setSelectedValue={setSelectedValue} /> :
            <Attribute color="#E6E0FF" name={rule.value+(rule.attribute.suffix || '')}/>
          }
        </Grid>
        {rule.target &&
        <Grid item xs={6}>
          <Typography variant="body1">Target</Typography>
        </Grid>}
        {rule.target &&
        <Grid item xs={6}>
          <Select value={rule.target.name} fullWidth>
            {Object.entries(knockoutTargets).map(([key, target]) => (
              <MenuItem key={key} value={target.name}>
                <Stack direction="row" alignItems="center" spacing={2}>
                <target.icon></target.icon>
                <Typography>{target.name}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>        
        </Grid>}
      </Grid>
      
    </Dialog>
  );
}
