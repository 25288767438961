import React from 'react';
import { Grid, Typography, Slider, Container, Stack, Alert, TextField, Card } from '@mui/material';
import { InputField, DatePickerField, RadioGroupField } from '../../FormFields';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';

const fields = {
  loanAmount: {
    name: 'loanAmount',
    label: 'Loan amount*',
    validation: Yup.string().required('Please enter your loan amount'),
    default: 100000
  }
};

const MIN = 1000;
const MAX = 5000000;

const View = function() {
  const {values, setFieldValue, setFieldTouched} = useFormikContext();
  const amountValue = values[fields.loanAmount.name] || 0;

  const handleSliderChange = (event, newValue) => {
    setFieldValue(fields.loanAmount.name, newValue);
  };
  
  return (
    <React.Fragment>

      <Grid container align='center' spacing={4} >
        <Grid item xs={12} sx={{m:5}}>
          <Typography variant='h5' color='text.secondary'>Drag for your requested loan amount</Typography>
        </Grid>

        <Grid item xs={12}>
          <Slider sx={{color:'primary.light', width:'60%'}}
            //color='secondary'
            value={amountValue}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={MIN}
            max={MAX}
            step={MIN}
            valueLabelDisplay="on"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{color:'primary.main', border:2, borderColor: 'info.light' , width:'60%', p:2, borderRadius: '4px'}} variant='h5'>Requested loan amount: <u>${amountValue.toLocaleString("en-US")}</u></Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default {
  caption: "Hi! I'm Emma",
  title: 'Easy 5-step loan application',
  View,
  fields
};
