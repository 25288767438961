export const categories = {
  accounting: "Accounting",
  payments: "Payments",
  subscription: 'Subscriptions',
  ecommerce: 'eCommerce',
  marketing: 'Marketing',
  analytics: 'Analytics',
  crm: "CRM",
  payroll: "Payroll",
  social: 'Social Media',
  bank: 'Bank',
  creditBureu: 'Credit Bureaus',
  businessDataAgreggators: 'Business Data Aggregators',
  governmentRegistries: 'Government Registries',
};

export const services = [
  { name: 'Experian', categories: [categories.creditBureu], logo: 'experian.svg' },
  { name: 'Equifax', categories: [categories.creditBureu], logo: 'equifax.svg' },
  { name: 'TransUnion', categories: [categories.creditBureu], logo: 'transunion.svg' },
  { name: 'D&B', categories: [categories.creditBureu], logo: 'dun-and-bradstreet.svg' },
  { name: 'Creditsafe', categories: [categories.creditBureu], logo: 'creditsafe.svg' },

  { name: 'Quickbooks', categories: [categories.accounting], logo: 'quickbooks.svg' },
  { name: 'ZOHO', categories: [categories.accounting, categories.crm], logo: 'zoho.svg' },
  { name: 'FreshBooks', categories: [categories.accounting], logo: 'freshbooks.svg' },
  { name: 'Bexio', categories: [categories.accounting], logo: 'bexio.svg' },
  { name: 'Expensify', categories: [categories.accounting], logo: 'expensify.svg' },
  { name: 'Free Agent', categories: [categories.accounting], logo: 'free-agent.svg' },
  { name: 'Sage Business Cloud', categories: [categories.accounting], logo: 'sage-business-cloud.svg' },
  { name: 'Xero', categories: [categories.accounting], logo: 'xero.svg' },
  { name: 'Wave', categories: [categories.accounting], logo: 'wave.svg' },
  { name: 'Kashoo', categories: [categories.accounting], logo: 'kakshoo.svg' },
  { name: 'Invoiced', categories: [categories.accounting], logo: 'invoiced.svg' },

  { name: 'Hubspot', categories: [categories.crm], logo: 'hubspot.svg' },
  { name: 'Pipedrive', categories: [categories.crm], logo: 'pipedrive.svg' },
  { name: 'Salesforce', categories: [categories.crm], logo: 'salesforce.svg' },
  { name: 'Copper', categories: [categories.crm], logo: 'copper.svg' },
  { name: 'AgileCRM', categories: [categories.crm], logo: 'agile-crm.svg' },
  { name: 'Follow Up Boss', categories: [categories.crm], logo: 'follow-up-boss.svg' },
  { name: 'Insightly', categories: [categories.crm], logo: 'insightly.svg' },
  { name: 'Keap', categories: [categories.crm], logo: 'keap.svg' },
  { name: 'Streak', categories: [categories.crm], logo: 'streak.svg' },
  { name: 'vcita', categories: [categories.crm], logo: 'vcita.svg' },
  { name: 'Retail CRM', categories: [categories.crm], logo: 'retail-crm.svg' },
  { name: 'Freshsales', categories: [categories.crm], logo: 'fresh-sales.svg' },

  { name: 'Square', categories: [categories.payments, categories.payroll], logo: 'square.svg' },
  { name: 'Stripe', categories: [categories.payments], logo: 'stripe.svg' },
  { name: 'Paypal', categories: [categories.payments], logo: 'paypal.svg' },
  { name: 'Hyperwallet', categories: [categories.payments], logo: 'hyper-wallet.svg' },
  { name: 'Qiwi', categories: [categories.payments], logo: 'qiwi.svg' },
  { name: 'LiqPay', categories: [categories.payments], logo: 'liq-pay.svg' },
  { name: 'Payoneer', categories: [categories.payments], logo: 'payoneer.svg' },
  { name: 'Brex', categories: [categories.payments], logo: 'brex.svg' },
  { name: 'Sum Up', categories: [categories.payments], logo: 'sum-up.svg' },
  { name: 'Clover', categories: [categories.payments], logo: 'clover.svg' },
  { name: 'Vend', categories: [categories.payments], logo: 'vend.svg' },
  { name: 'Lightspeed Retail', categories: [categories.payments], logo: 'lightspeed-retail.svg' },
  { name: 'Erply', categories: [categories.payments], logo: 'erply.svg' },
  { name: 'ePOS Now', categories: [categories.payments], logo: 'epos-now.svg' },
  { name: 'Shopify', categories: [categories.payments, categories.ecommerce], logo: 'shopify.svg' },

  { name: 'Amazon', categories: [categories.ecommerce], logo: 'amazon.svg' },
  { name: 'eBay', categories: [categories.ecommerce], logo: 'ebay.svg' },
  { name: 'Woo Commerce', categories: [categories.ecommerce], logo: 'woo-commerce.svg' },
  { name: 'Big Commerce', categories: [categories.ecommerce], logo: 'big-commerce.svg' },
  { name: 'Big Cartel', categories: [categories.ecommerce], logo: 'big-cartel.svg' },
  { name: 'Allegro', categories: [categories.ecommerce], logo: 'allegro.svg' },
  { name: 'Squarespace', categories: [categories.ecommerce], logo: 'square-space.svg' },
  { name: 'ecwid', categories: [categories.ecommerce], logo: 'ecwid.svg' },
  { name: 'Gumroad', categories: [categories.ecommerce], logo: 'gumroad.svg' },
  { name: '3d Cart', categories: [categories.ecommerce], logo: '3d-cart.svg' },
  { name: 'Mercado Libre', categories: [categories.ecommerce], logo: 'mercado-libre.svg' },
  { name: 'Etsy', categories: [categories.ecommerce], logo: 'etsy.svg' },
  { name: 'Lazada', categories: [categories.ecommerce], logo: 'lazada.svg' },
  { name: 'Magento', categories: [categories.ecommerce], logo: 'magento.svg' },

  { name: 'Gusto', categories: [categories.payroll], logo: 'gusto.svg' },
  { name: 'Paychex', categories: [categories.payroll], logo: 'paychex.svg' },
  { name: 'ADP', categories: [categories.payroll], logo: 'adp.svg' },
  { name: 'Zenefits', categories: [categories.payroll], logo: 'zenefits.svg' },

  { name: 'Data Axle', categories: [categories.marketing], logo: 'data-axle.svg' },
  { name: 'Mail Chimp', categories: [categories.marketing], logo: 'mail-chimp.svg' },
  { name: 'Facebook', categories: [categories.marketing, categories.social], logo: 'facebook-business.svg' },
  { name: 'Instagram', categories: [categories.marketing, categories.social], logo: 'instagram-business.svg' },
  { name: 'LinkedIn', categories: [categories.marketing, categories.social], logo: 'linkedin.svg' },
  { name: 'Google Ads', categories: [categories.marketing], logo: 'google-ads.svg' },
  { name: 'Twitter', categories: [categories.marketing, categories.social], logo: 'twitter.svg' },
  { name: 'YouTube', categories: [categories.marketing, categories.social], logo: 'youtube.svg' },
  { name: 'Active Campaign', categories: [categories.marketing], logo: 'active-campaign.svg' },
  { name: 'Klaviyo', categories: [categories.marketing], logo: 'klaviyo.svg' },
  { name: 'Maileon', categories: [categories.marketing], logo: 'maileon.svg' },
  { name: 'Criteo', categories: [categories.marketing], logo: 'criteo.svg' },
  { name: 'Customer.io', categories: [categories.marketing], logo: 'customer-io.svg' },
  { name: 'Marketo', categories: [categories.marketing], logo: 'marketo.svg' },
  { name: 'MediaMath', categories: [categories.marketing], logo: 'media-math.svg' },
  { name: 'Infusionsoft', categories: [categories.marketing], logo: 'infusion-soft.svg' },
  { name: 'Send Pulse', categories: [categories.marketing], logo: 'send-pulse.svg' },
  { name: 'Omni Send', categories: [categories.marketing], logo: 'omni-send.svg' },
  { name: 'Perfect Audience', categories: [categories.marketing], logo: 'perfect-audience.svg' },

  { name: 'Google Analytics', categories: [categories.analytics], logo: 'google-analytics.svg' },
  { name: 'Matomo', categories: [categories.analytics], logo: 'matomo.svg' },
  { name: 'Adobe Analytics', categories: [categories.analytics], logo: 'adobe-analytics.svg' },
  { name: 'Mixpanel', categories: [categories.analytics], logo: 'mixpanel.svg' },
  { name: 'Kissmetrics', categories: [categories.analytics], logo: 'kissmetrics.svg' },

  { name: 'Recurly', categories: [categories.subscription], logo: 'recurly.svg' },
  { name: 'Chargebee', categories: [categories.subscription], logo: 'chargebee.svg' },
  { name: 'SaaS Optics', categories: [categories.subscription], logo: 'saas-optics.svg' },
  { name: 'Zuora', categories: [categories.subscription], logo: 'zuora.svg' },
  { name: 'Braintree', categories: [categories.subscription], logo: 'braintree.svg' },
  { name: 'Chargify', categories: [categories.subscription], logo: 'chargify.svg' },
  { name: 'Paddle', categories: [categories.subscription], logo: 'paddle.svg' },
  { name: 'FastSpring', categories: [categories.subscription], logo: 'fast-spring.svg' },
];
