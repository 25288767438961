import React from "react";
import {
  Card,
  Button,
  Typography,
  Stack,
  Box,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";

import { InformationTable, CreateTable } from "./InformationTable";
import { DocumentsTable } from "./DocumentsTable";

import {
  values,
  createRow,
  collectSource,
  profitabilityAnalysis,
  liquidityAnalysis,
  operationalEfficiency,
  debtAnalysis,
  general,
  taxationAndLegal,
  web,
  social,
  local,
  owners,
} from "../data";

const Insights = () => {
  var filteredInsights = [
    ...profitabilityAnalysis,
    ...liquidityAnalysis,
    ...operationalEfficiency,
    ...debtAnalysis,
    ...general,
    ...taxationAndLegal,
    ...web,
    ...social,
    ...local,
  ];

  filteredInsights = filteredInsights.filter(
    (item) => item.validations && item.validations.length > 0
  );
  filteredInsights = filteredInsights.map((item) =>
    createRow(
      item.attribute,
      item.validations.map((validation) => validation.label).join(", "),
      item.details,
      item.sources,
      item.validations
    )
  );

  return (
    <React.Fragment>
      <CreateTable rows={filteredInsights} title="Insights"></CreateTable>
    </React.Fragment>
  );
};

export const DecisioningTab = () => {
  const [selectedTab, setTabValue] = React.useState(0);

  return (
    <Card sx={{ p: 6, height: "100%", borderRadius: "15px" }} elevation={0}>
      <Stack direction="row">
        <Typography variant="h4" color="textSecondary">
          Application Data
        </Typography>
        <Button sx={{ mx: 1 }}>
          <RefreshIcon fontSize="medium" />
        </Button>
        <Typography variant="body2" color="textSecondary" alignSelf="center">
          Last updated April 21, 2022 12:23
        </Typography>
        <Card
          sx={{
            alignSelf: "center",
            minWidth: "100px",
            backgroundColor: "primary.light",
            borderRadius: 15,
            marginLeft: "auto",
            marginRight: "0",
          }}
          elevation={0}
        >
          <Stack direction="row" spacing={1} sx={{ m: 1 }}>
            {collectSource.map(
              (source, index) =>
                source.Icon && <span key={index}>{source.Icon}</span>
            )}
          </Stack>
        </Card>
      </Stack>

      <Box sx={{ borderColor: "divider" }} pb={3}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          <Tab label="Information" />
          <Tab label="Documents" />
          <Tab label="Insights" />
          <Tab label="History (coming soon)" disabled={true} />
        </Tabs>
      </Box>

      {[InformationTable, DocumentsTable, Insights].map((Component, index) => (
        <div hidden={selectedTab !== index} key={index}>
          {selectedTab === index && (
            <Box sx={{ p: 0 }}>
              <Component />
            </Box>
          )}
        </div>
      ))}
    </Card>
  );
};
