import React from 'react';
import { TextField, Grid, Typography, Box, Alert } from '@mui/material';
import { InputField, DatePickerField, RadioGroupField } from '../../FormFields';
import * as Yup from 'yup';
import AutoComplete from 'react-google-autocomplete'
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormikContext } from 'formik';
import MultipleSelectField from '../../FormFields/MultipleSelectField';
import AutoCompleteMultiSelectField from '../../FormFields/AutoCompleteMultiSelectField';

const fields = {
  businessName: {
    name: 'businessName',
    label: 'Business Name',
    validation: Yup.string().required('Please enter your business name'),
    default: ''
  }, businessType: {
    name: 'businessType',
    label: 'Business Type',
    default: []
  }, businessAddress: {
    name: 'businessAddress',
    label: 'Business Address',
    default: ''
  }
};

const View = function() {
  const {values, setFieldValue} = useFormikContext();

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyBsybZIqyKz0xfPmd_-BiCzGb919aKg-_U',
    onPlaceSelected: (place, target) => {
      console.log(place);
      console.log(target.value)
      setFieldValue(fields.businessName.name, place.name);
      const filteredTypes = place.types.filter(type => type !== 'point_of_interest');
      setFieldValue(fields.businessType.name, filteredTypes);
      setFieldValue(fields.businessAddress.name, place.formatted_address);
    },
    options: {
      types: ['establishment'],
      fields:['formatted_address', 'address_components', 'geometry', 'name', 'place_id', 'types', 'place_id', 'url', 'rating', 'user_ratings_total', 'reviews', 'business_status'],
      componentRestrictions: { country: 'us' }
    },
  });

  return (
    <Box >
      <Grid container spacing={3} sx={{width:'500px'}}>
        <Grid item xs={12}>
        <InputField 
          inputRef={ref} 
          name={fields.businessName.name} 
          label={fields.businessName.label} 
          fullWidth
          />
        </Grid>

        <Grid item xs={12} >
        <InputField 
          name={fields.businessAddress.name} 
          label={fields.businessAddress.label} 
          fullWidth
          sx={!values[fields.businessName.name] && {display:'none'}}
          InputLabelProps={{ shrink: true }} // patch to fix label-on-top bug
          />
        </Grid>

        <Grid item xs={12} >
          <AutoCompleteMultiSelectField 
            options={options} 
            name={fields.businessType.name} 
            label={fields.businessType.label}
            sx={!values[fields.businessName.name] && {display:'none'}}
          />
        </Grid>
      </Grid>

      
    </Box>
  );
};

const Footer = function() {
  return <Alert severity="info" sx={{maxWidth:'680px', mb: 4}}>
    This loan application will enable pre-approval of a loan and will not affect your credit score. Prior to final loan approval, and with your full consent, the lending bank may perform a full credit check.
  </Alert>
}


export default {
  caption: "Let's start",
  title: "Please enter your business name",
  View,
  Footer,
  fields
};


const options = [
  'accounting',
  'airport',
  'amusement_park',
  'aquarium',
  'art_gallery',
  'atm',
  'bakery',
  'bank',
  'bar',
  'beauty_salon',
  'bicycle_store',
  'book_store',
  'bowling_alley',
  'bus_station',
  'cafe',
  'campground',
  'car_dealer',
  'car_rental',
  'car_repair',
  'car_wash',
  'casino',
  'cemetery',
  'church',
  'city_hall',
  'clothing_store',
  'convenience_store',
  'courthouse',
  'dentist',
  'department_store',
  'doctor',
  'drugstore',
  'electrician',
  'electronics_store',
  'embassy',
  'fire_station',
  'florist',
  'funeral_home',
  'furniture_store',
  'gas_station',
  'gym',
  'hair_care',
  'hardware_store',
  'hindu_temple',
  'home_goods_store',
  'hospital',
  'insurance_agency',
  'jewelry_store',
  'laundry',
  'lawyer',
  'library',
  'light_rail_station',
  'liquor_store',
  'local_government_office',
  'locksmith',
  'lodging',
  'meal_delivery',
  'meal_takeaway',
  'mosque',
  'movie_rental',
  'movie_theater',
  'moving_company',
  'museum',
  'night_club',
  'painter',
  'park',
  'parking',
  'pet_store',
  'pharmacy',
  'physiotherapist',
  'plumber',
  'police',
  'post_office',
  'primary_school',
  'real_estate_agency',
  'restaurant',
  'roofing_contractor',
  'rv_park',
  'school',
  'secondary_school',
  'shoe_store',
  'shopping_mall',
  'spa',
  'stadium',
  'storage',
  'store',
  'subway_station',
  'supermarket',
  'synagogue',
  'taxi_stand',
  'tourist_attraction',
  'train_station',
  'transit_station',
  'travel_agency',
  'university',
  'veterinary_care',
  'zoo',
];
