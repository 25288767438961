import React from "react";
import { Grid, Card } from "@mui/material";

import { CompanyDetails } from "./components/CompanyDetails";
import { MainTabs } from "./components/MainTabs";
import { DecisioningTab } from "./components/DecisioningTab";
import { ApplicationTab } from "./components/ApplicationTab";
import { useMemo } from "react";

const tabs = [ApplicationTab, DecisioningTab];

export default function ApplicationView() {
  const [selectedMainTab, setSelectedMainTab] = React.useState(0);

  const SelectedTabComponent = useMemo(
    () => tabs[selectedMainTab],
    [selectedMainTab]
  );

  const Layout = function () {
    return (
      <Grid container spacing={4} sx={{ p: 4 }}>
        <Grid item xs={12}>
          <Card
            sx={{
              p: 6,
              height: "100%",
              borderRadius: "15px",
              backgroundColor: "primary.light",
            }}
            elevation={0}
          >
            <CompanyDetails />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <MainTabs
            selectedTab={selectedMainTab}
            onTabChange={setSelectedMainTab}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectedTabComponent />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Card
            sx={{ p: 6, height: "100%", borderRadius: "15px" }}
            elevation={0}
          >
            <AutomatedCollateral />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card
            sx={{ p: 6, height: "100%", borderRadius: "15px" }}
            elevation={0}
          >
            <Action />
          </Card>
        </Grid> */}
      </Grid>
    );
  };

  // const AutomatedCollateral = () => {
  //   return (
  //     <React.Fragment>
  //       <Typography variant="h4">Automated Collateral</Typography>
  //       <Grid container spacing={2} sx={{ mt: 1 }}>
  //         <Grid item xs={8}>
  //           {getField(
  //             "LAMA Collateral Eligibility",
  //             "$" + values.collateralEligibility.toLocaleString("en-US")
  //           )}
  //         </Grid>
  //         <Grid item xs={4}>
  //           <Button variant="outlined" color="info" sx={{ height: "75px" }}>
  //             Accept and Recalculate
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </React.Fragment>
  //   );
  // };

  // const Action = () => {
  //   return (
  //     <React.Fragment>
  //       <Typography variant="h4">Actions</Typography>
  //       <Grid container spacing={2} sx={{ mt: 1 }}>
  //         <Grid item xs={4} align="center">
  //           <Button
  //             variant="outlined"
  //             color="success"
  //             sx={{ height: "75px" }}
  //             fullWidth
  //           >
  //             <CheckCircleIcon sx={{ mr: 1 }} />
  //             Issue Quote
  //           </Button>
  //         </Grid>
  //         <Grid item xs={4} align="center">
  //           <Button
  //             variant="outlined"
  //             color="error"
  //             sx={{ height: "75px" }}
  //             fullWidth
  //           >
  //             <ErrorIcon sx={{ mr: 1 }} />
  //             Decline
  //           </Button>
  //         </Grid>
  //         <Grid item xs={4} align="center">
  //           <Button
  //             variant="outlined"
  //             color="primary"
  //             sx={{ height: "75px" }}
  //             fullWidth
  //           >
  //             <PeopleIcon sx={{ mr: 1 }} />
  //             Find Participants
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </React.Fragment>
  //   );
  // };

  // const getField = function (title, value, extra) {
  //   return (
  //     <Grid item xs={12}>
  //       <Typography variant="body1">{title}</Typography>
  //       <Typography variant="h3" color="textSecondary">
  //         {value}
  //       </Typography>
  //       {extra}
  //     </Grid>
  //   );
  // };

  return <Layout />;
}
