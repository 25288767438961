import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {Paper, Grid, Card, Button, Typography, Avatar, Stack, Chip, Container, Tooltip, Alert, 
    Toolbar, IconButton, Box, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Menu } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';

import { makeStyles } from "@mui/styles";
import { styled, useTheme } from '@mui/material/styles';

import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import TuneIcon from '@mui/icons-material/TuneOutlined';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import LeaderboardIcon from '@mui/icons-material/LeaderboardOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CachedIcon from '@mui/icons-material/CachedOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';



const menu = [
    {label:"Pipeline", icon:AssignmentTurnedInIcon, selected: true, link:'/pipeline'},
    {label:"Products", icon:DashboardIcon, link:'/products'},
    {label:"Workflows", icon:MiscellaneousServicesIcon, link:'/workflows'},
    {label:"Exchange", icon:CachedIcon, link:'/exchange'},
    {label:"Portfolio", icon:AssignmentIcon},
    {label:"Integrations", icon:IntegrationInstructionsIcon},
    {label:"Teams", icon:GroupsIcon},
];

const useStyles = makeStyles(theme => ({
    customHoverFocus: {
      "&:hover, &.Mui-focusVisible": { backgroundColor: "white" }
    }
  }));

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));  

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


export default function DashboardMenu() {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const navigate = useNavigate();

    const toggleOpen = () => {
        setOpen(!open);
    };
    return (
        <Box>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                <Box sx={{ flexGrow: 1 }} >
                    <Stack direction="row">
                        <Box
                            alt="logo" 
                            component="img"
                            src={open ? "/logos/lama-ai-logo.png" : "/icons/lama-icon.svg"}
                            pl={2}
                        />
                    </Stack>
                </Box>
                <IconButton onClick={toggleOpen} className={classes.customHoverFocus} 
                    sx={{backgroundColor:'white', boxShadow:2, position:'fixed', left:(open ? '220px' : '44px'), zIndex: 'tooltip' }}
                >
                    {!open ? <KeyboardArrowRightIcon color='primary' /> : <KeyboardArrowLeftIcon  color='primary'/>}
                </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                {menu.map((item, index) => (
                    <ListItemButton
                    key={index}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    selected={window.location.pathname == item.link}
                    onClick={() => {item.link && navigate(item.link)} }
                    >
                    <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        }}
                    ><item.icon color='primary'/>
                        
                    </ListItemIcon>
                    <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                ))}
                </List>
                <Divider />
                
            </Drawer>
        </Box>
    );
}
