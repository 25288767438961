import React from "react";
import {
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { ReactComponent as VIcon } from '../../../assets/icons/v.svg';

import { values } from "../data";

const progressSteps = [
  "Application",
  "Decisioning",
  "Pending SBA",
  "Closing",
  "Off-Ramp",
];

const financingData = {
  title: "Total Financing",
  amount: "$1,500,000",
  chips: ["Construction", "Working Capital"],
};

const ApplicationProgress = () => (
  <Stepper activeStep={1} orientation="vertical">
    {progressSteps.map((step) => (
      <Step key={step}>
        <StepLabel>{step}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

const BusinessImage = () => (
  <Box
    borderRadius={2}
    alt="logo"
    component="img"
    sx={{ width: "98px", height: "98px" }}
    src="images/roofing-types.jpeg"
  />
);

const TotalFinancing = () => (
  <Stack spacing={1}>
    <Typography variant="body1">{financingData.title}</Typography>
    <Typography variant="h4"><b>{financingData.amount}</b></Typography>
    <Stack direction={"row"} spacing={1}>
      {financingData.chips.map((chip, index) => (
        <Chip label={chip} key={index} color="primary" variant="outlined" />
      ))}
    </Stack>
  </Stack>
);

const BusinessTags = () => (
  <Stack direction={"row"} spacing={1}>
    {values.businessType.map((businessType, index) => (
      <Chip
        label={businessType}
        key={index}
        color="primary"
        variant="outlined"
      />
    ))}
  </Stack>
);

const BusinessLinks = () => (
  <Stack direction={"row"}>
    {[
      <LanguageIcon fontSize="medium" />,
      <FacebookIcon fontSize="medium" />,
      <FmdGoodIcon fontSize="medium" />,
    ].map((icon, index) => (
      <IconButton color={"primary"} key={index}>
        {icon}
      </IconButton>
    ))}
  </Stack>
);

const BusinessDetails = () => (
  <Stack spacing={2}>
    <Stack direction={"row"} spacing={2} alignItems="center">
      <Typography variant="h4">
        <b>{values.companyName}</b>
      </Typography>
      <VIcon/>
    </Stack>
    <Box>
      <Typography variant="body2" color="textSecondary">
        {values.address}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {`${values.city}, ${values.state}`}
      </Typography>
    </Box>
    <Box pt={2}>
      <Typography variant="body2" color="textSecondary">
        {`Contact Person: ${values.firstName} ${values.lastName}`}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {values.email}
      </Typography>
    </Box>
    <Box pt={2}>
      <BusinessTags />
    </Box>
    <BusinessLinks />
  </Stack>
);

export const CompanyDetails = () => (
  <Stack direction={"row"} spacing={4} justifyContent="space-between">
    <Stack direction="row" spacing={4}>
      <BusinessImage />
      <BusinessDetails />
    </Stack>
    <TotalFinancing />
    <ApplicationProgress />
</Stack>
);
