import React from "react";
import {
  Card,
  Typography,
  Stack,
  Button,
  Checkbox,
  CardContent,
  Collapse,
  Paper,
  Switch,
  Grid,
  Dialog,
  DialogTitle,
  Box
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/EditOutlined';

function EditItemDialog(props) {
  const { onClose, selectedValue, open, EditView } = props;
    console.log(props.selectedValue)
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'md'}>
        <DialogTitle  mx={2}>
            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
            {selectedValue?.name}
            {selectedValue?.logo &&
            <img src={`/logos/${selectedValue.logo}`} height='64px' style={{marginTop:'16px'}} />} {/* marginTop is a patch that works for most of these icons */}
            </Stack>
        </DialogTitle>
        <Box minHeight={'300px'}  mx={4}>
          <EditView {...selectedValue}/>
        </Box>
    </Dialog>
  );
}

export default function GroupedList(props) {

  const [editItem, setEditItem] = React.useState(null);
  const [openGroup, setOpenGroup] = React.useState();

  const handleClickEdit = (item) => {
    setEditItem(item);
  };

  const handleCloseEdit = () => {
    setEditItem(null);
  };

  const handleToggleGroup = (group, element) => {
    console.log(element);
    if (openGroup === group) {
      setOpenGroup(null);
    }
    else setOpenGroup(group);
  };

  return (
    <Stack spacing={2}>
      {props.groups.map((group, index) => (
          <Card sx={{ borderRadius: "15px", px:5 }} elevation={0} key={index} >
            <Stack direction="row" justifyContent={'space-between'} alignItems="center" spacing={2} sx={{cursor:"pointer"}} my={5}
            onClick={(event, element) => {handleToggleGroup(group, element)}} >
                <Typography variant="h5" sx={{fontWeight:'500'}} color="#666666">
                {group.name}
                </Typography>
                <Stack direction="row" alignItems={'center'} spacing={2}>
                    {props.newButton && <Button startIcon={<AddIcon/>} variant="outlined">Add</Button>}
                    {openGroup === group ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </Stack>
            </Stack>

            <Collapse in={group==openGroup} unmountOnExit>
              <Stack spacing={2} mb={5}>
                  {group.items.map((groupItem, index) => (
                  <Card elevation={0} key={index} 
                          sx={{ height: "100%", borderRadius: "4px", border: 1, borderColor: 'grey.300' }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" px={4} py={3}>
                        {<groupItem.ItemTitle {...groupItem} />}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Switch defaultChecked/>
                          <Button onClick={() => handleClickEdit(groupItem)}>
                            <EditIcon />
                          </Button>
                        </Stack>
                      </Stack>
                  </Card>
                  ))}
              </Stack>
            </Collapse>
          </Card>
      ))}

      <EditItemDialog
        selectedValue={editItem}
        open={!!editItem}
        onClose={handleCloseEdit}
        EditView={props.EditView}
      />
    </Stack>
  );
}
