import React from 'react';
import {Grid, Card, Button, Typography, Stack, Chip, SvgIcon, FormControl, InputLabel,
    Box, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


export default function FilterSelection(props) {
    return  <Box sx={{ minWidth: 160 }} width='100%' m={1} bgcolor={'white'}>
    <FormControl fullWidth>
        <InputLabel>{props.name}</InputLabel>
        <Select label={props.name} IconComponent = {props.icon}>
            {props.items?.map((item, index) => ( <MenuItem key={index} value={item.value}>{item.label}</MenuItem> ))}
        </Select>
    </FormControl>
    </Box>
}