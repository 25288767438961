import React from "react";
import {
  Card,
  Typography,
  Stack,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  CircularProgress
} from "@mui/material";

import LinesEllipsis from 'react-lines-ellipsis'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HistoryIcon from '@mui/icons-material/History';


import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";
import { ReactComponent as ProductIcon } from '../../assets/icons/product.svg';
import { ReactComponent as ExchangeIcon } from '../../assets/icons/exchange.svg';

import { Rule } from './tabs/Rules'
import { attributes } from './templates/TermLoan'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/EditOutlined';
import EditRule from "./tabs/EditRule";

const exchangeEligibility = [
    {attribute: attributes.requestedAmount, operator: 'between', value: '$50K - $1.5M', active: true},
    {attribute: attributes.timeInBusiness, operator: 'less than', value: '2 years',active: true},
    {attribute: attributes.industry, operator: 'not in', value: 'Cannabis, Adult Content, ...', active: true},
    {attribute: attributes.identityVerification, operator: 'is not', value: 'Suspicious', active: false},
  ]

const offrampRestrictions = [
    {attribute: attributes.privateLenders, operator: 'are', value: 'Allowed', active: true},
    {attribute: attributes.partnerActiveDistance, operator: 'more than', value: 100, active: true},
    {attribute: attributes.partnerRating, operator: 'more than', value: 9, active: true},
]

const expectedTerms = [
    {label: 'Approval Rates', value: '50-65%', Icon: CompareArrowsIcon},
    {label: 'Expected APR', value: '10-12%', Icon: CompareArrowsIcon},
    {label: 'Approval Time', value: '1-4 days', Icon: HistoryIcon},
]

export default function ConfigureExchangeHostedProduct() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedRule, setSelectedRule] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleClickEditRule = (rule) => {
    setSelectedRule(rule);
  };

  const handleCloseEditRule = (rule) => {
    setSelectedRule(null);
    setLoading(true);
    setTimeout(()=> setLoading(false), 2000);
  };

  return (
    <Box width={'100%'}  m={5} sx={{borderRadius:'15px'}}>
        <Card >
            <Stack spacing={5} p={6}>
                <Typography variant="h4">
                    Confirm new hosted product
                </Typography>

                <Divider />

                <Grid container>
                    <Grid item xs={6}>
                <Stack spacing={3}>
                <Typography variant="h6" >Exchange Eligibility</Typography>
                {exchangeEligibility.map((rule, index) => 
                    <Rule rules={[rule]} key={index}/>
                )}
                </Stack>
                </Grid>
                <Grid item xs={6}>
                {/* <Divider/> */}

                <Stack spacing={3}>
                    <Typography variant="h6" >Offramp Restrictions</Typography>
                    {offrampRestrictions.map((rule, index) => 
                        <Stack direction="row" key={index} spacing={2}>
                            <Rule rules={[rule]}/>
                            <Button onClick={() => handleClickEditRule(rule)}>
                                <EditIcon/>
                            </Button>
                        </Stack>
                    )}
                </Stack>

                </Grid>
                </Grid>
                <Divider />
                <Stack spacing={3}>
                    <Typography variant="h6">Expected Terms</Typography>
                    {loading ? <Stack alignItems={'center'}><CircularProgress/></Stack> : 
                    <Stack direction="row" spacing={2}>
                        {expectedTerms.map((expectedTerm, index) => (
                            <Card elevation={0} m={4} key={index}
                            sx={{width:'180px', height: "100%", borderRadius: "15px", border: 1, borderColor: 'grey.300'}}>
                                <Stack spacing={1} alignItems="center" p={3}>
                                    <Stack p={'12px'} bgcolor={'primary.light'} alignItems={'center'} justifyContent={'center'} borderRadius={100}>
                                        {<expectedTerm.Icon color="primary" sx={{fontSize: '24px'}}/>}
                                    </Stack>
                                    <Typography variant="body1">{expectedTerm.label}</Typography>
                                    <Typography variant="body2" color="text.secondary">{expectedTerm.value}</Typography>
                                </Stack>
                            </Card>
                        ))}
                    </Stack>}
                    </Stack>

                <Divider />

                <Stack spacing={2} justifyContent="right" width="100%">
                    <Box>
                        <Button variant='contained' endIcon={<ArrowForwardIcon/>} 
                            onClick={() => navigate('/products/')}>
                            Add Product
                        </Button>
                    </Box>
                    <Typography variant="body2" color="text.secondary" >I accept the terms of this credit product</Typography>
                </Stack>
            </Stack>
        </Card>
        <EditRule
        rule={selectedRule}
        open={selectedRule != null}
        onClose={handleCloseEditRule}
      />
    </Box>
  );
}
