import React from "react";
import {
  Grid,
  Card,
  Typography,
  Stack,
  Chip,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';

import { useTheme } from "@mui/styles";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PaidIcon from "@mui/icons-material/Paid";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import LandscapeIcon from "@mui/icons-material/Landscape";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";

import FilterSelection from "./FilterSelection";
import PipelineTab from "./PipelineTab";

// Filter icons
import Person from "@mui/icons-material/Person";
import DvrIcon from "@mui/icons-material/Dvr";
import AvTimerIcon from "@mui/icons-material/AvTimer";

// Engagement
import { ReactComponent as Engagement1 } from "../../assets/engagement/engagement-1.svg";
import { ReactComponent as Engagement2 } from "../../assets/engagement/engagement-2.svg";
import { ReactComponent as Engagement3 } from "../../assets/engagement/engagement-3.svg";
import { ReactComponent as Engagement4 } from "../../assets/engagement/engagement-4.svg";
import { ReactComponent as Engagement5 } from "../../assets/engagement/engagement-5.svg";
import { ReactComponent as Engagement6 } from "../../assets/engagement/engagement-6.svg";
import { ReactComponent as Engagement7 } from "../../assets/engagement/engagement-7.svg";
import { ReactComponent as Engagement8 } from "../../assets/engagement/engagement-8.svg";
import { ReactComponent as Engagement9 } from "../../assets/engagement/engagement-9.svg";
import { ReactComponent as Engagement10 } from "../../assets/engagement/engagement-10.svg";

import { Funnel } from "funnel-react";

// Business Types Icons
const businessTypeIcons = {
  medical: { label: "Medical", icon: <MedicalServicesIcon /> },
  landscaping: { label: "Lanscaping", icon: <LandscapeIcon /> },
  restaurant: { label: "Restaurant", icon: <FastfoodIcon /> },
  store: { label: "Convenience Store", icon: <LocalConvenienceStoreIcon /> },
  freight: { label: "Freight", icon: <LocalShippingIcon /> },
  realEstate: { label: "Real Estate", icon: <AddBusinessIcon /> },
  accounting: { label: "Accounting", icon: <PaidIcon /> },
};

const statuses = {
  application: { label: "Application", color: "primary" },
  rejected: { label: "Rejected", color: "error" },
  decisioning: { label: "Decisioning", color: "info" },
  closing: { label: "Closing", color: "primary" },
  completed: { label: "Completed", color: "success" },
};

// Owners
const createApplication = function (
  company,
  businessType,
  loanType,
  amount,
  useOfFunds,
  maxRate,
  riskScore,
  fastApprovalGuarantee,
  lamaCollateral,
  traditionalCollateral,
  businessLocation,
  craLoan,
  status,
  engagement,
  link
) {
  return {
    company,
    businessType,
    loanType,
    amount,
    useOfFunds,
    maxRate,
    riskScore,
    fastApprovalGuarantee,
    lamaCollateral,
    traditionalCollateral,
    businessLocation,
    craLoan,
    engagement,
    status,
    link,
  };
};

const applications = [
  createApplication(
    "Chicago Roofing Services",
    businessTypeIcons.landscape,
    "SBA 7(a) Business Loan",
    1500000,
    "Working Capital",
    "10%",
    "690",
    "",
    "90%",
    "-",
    "IL",
    "Yes",
    statuses.application,
    <div />,
    '/application?id=23432-482695-234'
  ),
  createApplication(
    "Sam's Deli",
    businessTypeIcons.medical,
    "SBA 7(a) Business Loan",
    250000,
    "Working Capital",
    "10%",
    "690",
    "",
    "90%",
    "-",
    "Nebraska",
    "Yes",
    statuses.application,
    <Engagement1 />
  ),
  createApplication(
    "Burger King San Francisco",
    businessTypeIcons.freight,
    "Equipment Financing",
    550000,
    "Commercial Vehicles",
    "6%",
    "750",
    "Yes",
    "100%",
    "-",
    "California",
    "No",
    statuses.rejected,
    <Engagement2 />
  ),
  createApplication(
    "Ultimate Garage",
    businessTypeIcons.restaurant,
    "Equipment Financing",
    100000,
    "Restaurant Equipment",
    "10%",
    "820",
    "",
    "60%",
    "-",
    "Kansas",
    "Yes",
    statuses.completed,
    <Engagement3 />
  ),
  createApplication(
    "Abercrombie",
    businessTypeIcons.landscaping,
    "SBA Express",
    300000,
    "Short-Term",
    "18%",
    "620",
    "",
    "70%",
    "-",
    "Nebraska",
    "Yes",
    statuses.decisioning,
    <Engagement4 />
  ),
  createApplication(
    "Mike's Construction Company",
    businessTypeIcons.realEstate,
    "CRE",
    6250000,
    "Hotel Financing",
    "11%",
    "740",
    "",
    "0%",
    "Property",
    "Nebraska",
    "No",
    statuses.closing,
    <Engagement5 />
  ),
  createApplication(
    "Springfield Accounting",
    businessTypeIcons.restaurant,
    "Line of Credit",
    75000,
    "Inventory Financing",
    "16%",
    "700",
    "Yes",
    "75%",
    "-",
    "New York",
    "No",
    statuses.application,
    <Engagement6 />
  ),
  createApplication(
    "BBB Burgers Inc.",
    businessTypeIcons.medical,
    "Equipment Financing",
    2500000,
    "Medical Equipment",
    "7%",
    "810",
    "Yes",
    "100%",
    "-",
    "Nebraska",
    "No",
    statuses.rejected,
    <Engagement7 />
  ),
  createApplication(
    "Pizza Hut NY",
    businessTypeIcons.store,
    "Line of Credit",
    85000,
    "Inventory Financing",
    "15%",
    "670",
    "Yes",
    "90%",
    "-",
    "Colorado",
    "Yes",
    statuses.application,
    <Engagement8 />
  ),
  createApplication(
    "Tats n' Hats",
    businessTypeIcons.realEstate,
    "CRE",
    1000000,
    "Property Development",
    "8%",
    "790",
    "",
    "0%",
    "Property",
    "Texas",
    "No",
    statuses.decisioning,
    <Engagement9 />
  ),
  createApplication(
    "Tom's Hardware Shop",
    businessTypeIcons.medical,
    "Practice Financing",
    150000,
    "Practice Acquisition",
    "12%",
    "590",
    "",
    "0%",
    "Personal Residence",
    "Nebraska",
    "No",
    statuses.completed,
    <Engagement10 />
  ),
];

const filterApplications = (status) =>
  applications.filter((application) => application.status === status);

const tabs = [
  { name: "Pipeline", applications: applications },
  { name: "New", applications: filterApplications(statuses.application) },
  {
    name: "Likely to Close",
    applications: filterApplications(statuses.closing),
  },
  { name: "At Risk", applications: filterApplications(statuses.rejected) },
  { name: "Closed", applications: filterApplications(statuses.completed) },
];

const bankers = [{ label: "John Doe", value: "john" }];

const loanTypes = [
  { label: "SBA 7(a)", value: "sba" },
  { label: "Business Term Loan", value: "term" },
  { label: "Consumer Term Loan", value: "consumer" },
  { label: "Equipment Financing", value: "equipment" },
];

const timePeriods = [
  { label: "Past Month", value: "month" },
  { label: "Previous Month", value: "previousMonth" },
  { label: "Past Quarter", value: "quarter" },
  { label: "Past Year", value: "year" },
  { label: "All", value: "all" },
  { label: "Custom Range", value: "custom" },
];

const funnelData = [
  { label: "Started Application", quantity: 135 },
  { label: "Completed Application", quantity: 84 },
  { label: "Bank Approval", quantity: 49 },
  { label: "SBA Approval", quantity: 29 },
  { label: "Accepted", quantity: 22 },
  { label: "Closed", quantity: 20 },
];

export const addCommasToNumber = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export default function Pipeline() {
  const [selectedTabName, setSelectedTabNameValue] = React.useState("Pipeline");
  const theme = useTheme();
  const navigate = useNavigate();

  const selectedTab = tabs.find((tab) => tab.name === selectedTabName);

  const Layout = function () {
    return (
      <Grid container spacing={2} sx={{ p: 4, overflowX: "auto" }}>
        <Grid item xs={12}>
          <Stack direction={"row"}>
            <FilterSelection name="Banker" icon={Person} items={bankers} />
            <FilterSelection
              name="Loan Type"
              icon={DvrIcon}
              items={loanTypes}
            />
            <FilterSelection
              name="Time Period"
              icon={AvTimerIcon}
              items={timePeriods}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%", borderRadius: "15px" }} elevation={0}>
            <Stack direction={"row"} justifyContent="space-between">
              {tabs.map((tab, index) => (
                <PipelineTab
                  key={index}
                  tab={tab}
                  setSelected={setSelectedTabNameValue}
                  selectedTab={selectedTabName}
                />
              ))}
            </Stack>
            <Flow applications={selectedTab.applications} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{ height: "100%", borderRadius: "15px", p: 4 }}
            elevation={0}
          >
            <Typography variant="h4" mb={4}>
              Prospect Funnel
            </Typography>
            <Funnel
              labelKey="label"
              valueKey="quantity"
              height={180}
              colors={{
                graph: ["#1890FF", "#BAE7FF"],
                percent: "black",
                label: "black",
                value: "black",
              }}
              displayPercent={true}
              data={funnelData}
            />
          </Card>
        </Grid>
      </Grid>
    );
  };

  const Flow = (props) => {
    const alignText = "left";

    return (
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={alignText}>Company</TableCell>
                <TableCell align={alignText}>Loan Type</TableCell>
                <TableCell align={alignText}>Amount</TableCell>
                <TableCell align={alignText}>Engagement</TableCell>
                <TableCell align={alignText}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.applications.map((row) => (
                <TableRow
                  key={row.company}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: row.link ? 'pointer' : 'default' }}
                  onClick={() => {row.link && navigate(row.link)} }
                >
                  <TableCell align={alignText}>{row.company}</TableCell>
                  <TableCell align={alignText}>
                    <Chip
                      sx={{ px: 1 }}
                      label={row.loanType}
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align={alignText}>
                    ${addCommasToNumber(row.amount)}
                  </TableCell>
                  <TableCell align={alignText}>{row.engagement}</TableCell>
                  <TableCell align={alignText}>
                    <Chip
                      sx={{ px: 1 }}
                      label={row.status.label}
                      color={row.status.color}
                      variant="outlined"
                    />
                  </TableCell>{" "}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const getField = function (title, value, extra) {
    return (
      <Grid item xs={6}>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="h3" color="textSecondary">
          {value}
        </Typography>
        {extra}
      </Grid>
    );
  };

  const getChips = function (values, props) {
    return (
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {values.map((value, index) => (
          <Grid item key={index}>
            {" "}
            <Chip label={value} {...props} />{" "}
          </Grid>
        ))}
      </Grid>
    );
  };

  return <Layout />;
}
