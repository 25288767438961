import React from "react";
import { useTheme } from "@mui/material";

export const AutomaticDocumentGatheringIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.1777" cy="24" r="24" fill={theme.palette.primary.light} />
      <mask
        id="mask0_9284_104966"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="25"
        height="24"
      >
        <rect x="12.1777" y="12" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9284_104966)">
        <path
          d="M30.6773 20.6L29.6273 18.25L27.2773 17.2L29.6273 16.125L30.6773 13.8L31.7273 16.125L34.0773 17.2L31.7273 18.25L30.6773 20.6ZM30.6773 34.2L29.6273 31.875L27.2773 30.8L29.6273 29.75L30.6773 27.4L31.7273 29.75L34.0773 30.8L31.7273 31.875L30.6773 34.2ZM21.0523 30.8L18.9523 26.125L14.2773 24L18.9523 21.875L21.0523 17.2L23.1773 21.875L27.8523 24L23.1773 26.125L21.0523 30.8ZM21.0523 27.15L22.0523 25L24.2023 24L22.0523 23L21.0523 20.85L20.0523 23L17.9023 24L20.0523 25L21.0523 27.15Z"
          fill={theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
