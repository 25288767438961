import { ReactComponent as SendToNetworkIcon } from '../../../assets/icons/send-to-network.svg';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import StoreIcon from '@mui/icons-material/StoreOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import AltRouteIcon from '@mui/icons-material/AltRouteOutlined';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';


const requirementsGroups = [
  {name: 'Loan Request', requirements: [{name: 'Contact Information', defaultFields: ['First Name', 'Last Name', 'Email Address', 'Phone Number'], defaultNonRequiredFields: ['Address', 'DOB', 'SSN']},
  'Business Plan',  '2 Year P&L Projections', 'Equipment Quotes', 'Refinancing Agreements', 'Collateral', 'Business Acquisition']},
  {name: 'Business', requirements: ['Business Identification', 'Stakeholders',  'Business Services', 'Bank Statements', 'Tax Returns', 'Financial Statements', 'Debt Notes', 'Buxiness Certificate', 'Loan Application History', 'Property Lease', 'Assets', 'Legal']},
  {name: 'Guarantors', requirements: ['Identification', 'ID Card Verification', 'Bank Statements', 'Tax Returns', 'Resume', 'Personal Financial Statement', 'Income Verification', 'Assets', 'Debt', 'Education', 'Legal' ]},
  {name: 'Affiliates', requirements: ['Identification', 'Tax Returns', 'Financial Statements', 'Purchase Agreement or LOI', 'Proposed Bill of Sale', 'Assets & Valuation', 'Licesnsing Agreements', 'Proof of Equity Injection']},
]

export const attributes = {
  timeInBusiness: {name:'Time in Business', icon:HistoryIcon, max: 5 , suffix: ' years'},
  industry: {name:'Industry', icon:StoreIcon},
  identityVerification: {name:'Identity Verification', icon:SearchIcon},
  requestedAmount: {name:'Requested Amount', icon:PaymentsIcon, max:10000000},  
  dataValidationScore: {name:'Lama Score', icon:BarChartIcon, max: 100, suffix: '%'},
  kycVerification: {name:'KYC Verification', icon:BarChartIcon, max: 100},
  annualRevenue: {name:'Annual Revenue', icon:PaymentsIcon},
  colateralValue: {name:'Collateral Value', icon:PaymentsIcon},
  collateralAssessment: {name:'Collateral Assessment', icon:BarChartIcon},
  partnerActiveLocations: {name:'Partner Active Locations', icon:LocationOnIcon},
  partnerActiveDistance: {name:'Partner Active Distance', icon:LocationOnIcon, max: 500 , suffix: ' miles'},
  partners: {name:'Partners', icon:StoreIcon},
  partnerRating: {name:'Partner Rating', icon:StarBorderOutlinedIcon, max: 10 , suffix: ' / 10'},
  privateLenders: {name:'Private Lenders', icon:StarBorderOutlinedIcon, options: ['Allowed', 'Not Allowed', 'Last Priority']},
  apr: {name:'Loan APR', icon:PaymentsIcon, suffix: '%'},
  timetToQuote: {name:'Time To Quote', icon:HistoryIcon},

  globalDSCR: {name:'Global DSCR', icon:BarChartIcon, max: 10},
  DSCR: {name:'DSCR', icon:BarChartIcon, max: 10},
  personalFicoScore: {name:'Personal FICO Score', icon:StarBorderOutlinedIcon, max: 800},
  loanAmountCondition: {name:'Loan Amount Condition', icon:PaymentsIcon, max: 100},
  sbss: {name:'SBSS', icon:StarBorderOutlinedIcon, max: 1000},

  existingRelationshipLength: {name:'Existing Relationship Length', icon:HistoryIcon, suffix: ' months'},
  slowPayments: {name:'Slow Payments', icon:PaymentsIcon},
  nonSufficientFunds: {name:'Non-sufficient Funds', icon:PaymentsIcon},
  creditUtilization: {name:'Credit Utilization', icon:PaymentsIcon},
  exposure: {name:'Bank Exposure', icon:GppMaybeOutlinedIcon},

};

const knockoutTargets = {
  reject: {name: 'Not Eligible', icon: <DoNotDisturbIcon/>},
  sendToNetwork: {name: 'Send to Network', icon: <SendToNetworkIcon/>},
  alternativeProduct: {name: 'Suggest Alternative', icon: <AltRouteIcon color="primary"/>},
}

const knockoutRules = [
  {attribute: attributes.timeInBusiness, operator: 'less than', value: 2, target: knockoutTargets.alternativeProduct, active: true},
  {attribute: attributes.industry, operator: 'in', value: 'Restaurants, Gas/Oil, ...', target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.industry, operator: 'in', value: 'Cannabis, Adult Content, ...', target: knockoutTargets.reject, active: true},
  {attribute: attributes.identityVerification, operator: 'is', value: 'Suspicious', target: knockoutTargets.reject, active: false},

  {attribute: attributes.globalDSCR, operator: 'less than', value: 1, target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.personalFicoScore, operator: 'less than', value: 640, target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.requestedAmount, operator: 'more than', value: 100_000, target: knockoutTargets.alternativeProduct, active: true},
  {attribute: attributes.loanAmountCondition, operator: 'less than', value: 65, target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.sbss, operator: 'more than', value: 155, target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.colateralValue, operator: 'is', value: 'UCC First Position', target: knockoutTargets.sendToNetwork, active: true},
]

const knockoutRulesGateCity = [
  {attribute: attributes.requestedAmount, operator: 'more than', value: "$100,000", target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.timeInBusiness, operator: 'less than', value: 2, target: knockoutTargets.reject, active: true},
  {attribute: attributes.industry, operator: 'in', value: 'Marijuana, ATM, Money service', target: knockoutTargets.reject, active: true},
  {attribute: attributes.existingRelationshipLength, operator: 'less than', value: 12, target: knockoutTargets.reject, active: true},
  {attribute: attributes.slowPayments, operator: 'more than', value: 0, target: knockoutTargets.reject, active: true},
  {attribute: attributes.nonSufficientFunds, operator: 'more than', value: 5, target: knockoutTargets.reject, active: true},
  {attribute: attributes.personalFicoScore, operator: 'less than', value: 720, target: knockoutTargets.sendToNetwork, active: true},
  {attribute: attributes.DSCR, operator: 'less than', value: 1.3, target: knockoutTargets.reject, active: true},
  {attribute: attributes.creditUtilization, operator: 'more than', value: "80%($15K), 50%($50K)", target: knockoutTargets.reject, active: true},]

const offrampRestrictionsOld = [
  {attribute: attributes.partnerActiveLocations, operator: 'not in', value: 'AL, OH, FL, ...', active: true},
  {attribute: attributes.partnerActiveDistance, operator: 'more than', value: 100, active: true},
  {attribute: attributes.partnerRating, operator: 'more than', value: 9, active: true},
  {attribute: attributes.industry, operator: 'not in', value: 'Cannabis, Adult Content, ...', active: true},
]

const offrampRestrictions = [
  {attribute: attributes.partners, operator: 'in', value: 'Small Business Bank, Kabbage, Bluevine', active: true},
  {attribute: attributes.apr, operator: 'less than', value: 18, active: true},
  {attribute: attributes.partnerRating, operator: 'more than', value: 8, active: true},
  {attribute: attributes.timetToQuote, operator: 'is', value: 'Real-time', active: true},
]

const inboundAppetite = [
  {attribute: attributes.industry, operator: 'not in', value: 'Cannabis, Adult Content, ...', active: true},
  {attribute: attributes.identityVerification, operator: 'is not', value: 'Suspicious', active: true},

]

const automationRulesOld = [
  {name: 'Unverified Customer', assign: 'Small business team', rules: [[{attribute: attributes.requestedAmount, operator: 'less than', value: '$150K'}], [{attribute: attributes.dataValidationScore, operator: 'less than', value: 90}, {attribute: attributes.kycVerification, operator: 'less than', value: 95}]]},
  {name: 'Large SMB deal', assign: 'Small business team', sendTo:'nCino LOS', rules: [[{attribute: attributes.requestedAmount, operator: 'between', value: '$150K - $5M'}], [{attribute: attributes.annualRevenue, operator: 'less than', value: '$20M'}]]},
  {name: 'Commercial deal', assign: 'Commercial team', sendTo: 'commercialapplications@sbb.com', rules: [[{attribute: attributes.requestedAmount, operator: 'above', value: '$3M'}], [{attribute: attributes.annualRevenue, operator: 'more than', value: '$20M'}]]},
  {name: 'Appraisal', assign: 'Appraiser', rules: [[{attribute: attributes.colateralValue, operator: 'more than', value: '$1M'}], [{attribute: attributes.collateralAssessment, operator: 'is', value: 'Unverified'}]]},

]

const automationRules = [
  {name: 'Small Ticket Loan', assign: 'Approve', rules: [[{attribute: attributes.requestedAmount, operator: 'less than', value: '$30K'}], [{attribute: attributes.personalFicoScore, operator: 'more than', value: 660}], [{attribute: attributes.annualRevenue, operator: 'more than', value: '$500K'}]]},
  {name: 'Midsize Loan, Large Merchant', assign: 'Approve', rules: [[{attribute: attributes.requestedAmount, operator: 'between', value: '$30K - $75K'}], [{attribute: attributes.personalFicoScore, operator: 'more than', value: 660}], [{attribute: attributes.annualRevenue, operator: 'more than', value: '$750K'}]]},
  {name: 'Manual Approval', assign: 'Manual Review', sendTo: 'Loan Origination System', rules: [[{attribute: attributes.requestedAmount, operator: 'more than', value: '$75K'}, {attribute: attributes.personalFicoScore, operator: 'less than', value: 700}], [{attribute: attributes.annualRevenue, operator: 'less than', value: '$500K'}]]},
  {name: 'Manual Data Review', assign: 'Manual Data Review', sendTo: 'backoffice@company.com', rules: [[{attribute: attributes.personalFicoScore, operator: 'is', value: 'Not Found'}, {attribute: attributes.kycVerification, operator: 'is', value: 'Unverified'}]]},
]

export const TermLoan = { requirementsGroups, knockoutRules: knockoutRulesGateCity, automationRules, offrampRestrictions, inboundAppetite};


