import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { Document, Page } from "react-pdf";

import {
  Chip,
  Typography,
  Stack,
  Avatar,
  Box,
  Button,
  Backdrop,
  Divider,
  TextField,
} from "@mui/material";

const statusToColor = {
  Pending: "warning",
  Approved: "success",
  Problem: "error",
  Review: "info",
};

const Row = ({ group, name, status, onIconClick }) => {
  return (
    <>
      <TableRow
        key={name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Typography variant="body1">{group}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body1">{name}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            variant="outlined"
            color={statusToColor[status]}
            label={status}
          />
        </TableCell>
        <TableCell>
          <Button
            sx={{ "&:hover": { bgcolor: "white" }, zIndex: 0 }}
            onClick={onIconClick}
          >
            <Avatar sx={{ bgcolor: "secondary.light", zIndex: 0 }}>
              <VisibilityOutlined sx={{ color: "secondary.dark" }} />
            </Avatar>
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const Comment = ({ name, content, time }) => (
  <Stack direction="row" spacing={1} alignItems="flex-start">
    <Avatar sx={{ width: "26px", height: "26px" }} />
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="body1" fontSize="14px" fontWeight={500}>
          {name}
        </Typography>
        <Typography variant="body2" fontSize={"10px"} color="text.disabled">
          {time}
        </Typography>
      </Stack>
      <Typography variant="body2" fontSize={"12px"} fontWeight={500}>
        {content}
      </Typography>
    </Stack>
  </Stack>
);

export const DocumentsTable = () => {
  const [documentOpen, setDocumentOpen] = React.useState(false);
  const [commentContent, setCommentContent] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);

  const [comments, setComments] = React.useState([
    {
      name: "Nave More",
      time: "Yesterday",
      content: "Wow! This Document Looks Amazing!",
    },
    { name: "Itay Maoz", time: "2 Hours Ago", content: "Yes, I agree" },
  ]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Group</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Preview</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            group="Assets"
            name="Residential Purchase Agreement.pdf"
            status="Pending"
            onIconClick={() => setDocumentOpen(true)}
          />
          <Row
            group="Revenue"
            name="Bank Statements.pdf"
            status="Approved"
            onIconClick={() => setDocumentOpen(true)}
          />
          <Row
            group="Expenses"
            name="CC Expenses Report.xls"
            status="Review"
            onIconClick={() => setDocumentOpen(true)}
          />
        </TableBody>
      </Table>
      <Backdrop
        open={documentOpen}
        onClick={() => setDocumentOpen(false)}
        sx={{ alignItems: "flex-start", py: 15 }}
      >
        <Box
          maxWidth="lg"
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            bgcolor: "white",
            minWidth: "md",
            maxHeight: "600px",
            overflow: "auto",
            borderRadius: "10px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Stack direction="row">
            <Stack sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Document file="/pdfs/Residential Purchase Agreement.pdf">
                <Page pageNumber={pageNumber} />
              </Document>
              <Stack
                direction="row"
                width={"100%"}
                justifyContent="space-between"
              >
                <Button
                  onClick={() => {
                    pageNumber === 1
                      ? pageNumber
                      : setPageNumber(pageNumber - 1);
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => {
                    pageNumber === 5
                      ? pageNumber
                      : setPageNumber(pageNumber + 1);
                  }}
                >
                  Next
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ borderLeft: 1, borderColor: "divider", pb: 2 }}>
              <Box sx={{ px: 3, pt: 5, pb: 2 }}>
                <Typography variant="h6">Comments</Typography>
              </Box>
              <Divider width="100%" />
              <Stack sx={{ px: 3, pt: 2 }} spacing={2} flex={1}>
                <Typography variant="alert">Today</Typography>
                {comments.map(({ name, time, content }) => (
                  <Comment name={name} time={time} content={content} />
                ))}
                <Stack
                  pt={3}
                  justifyContent="flex-end"
                  width="100%"
                  spacing={1}
                  flex={1}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Reply"
                    value={commentContent}
                    onChange={(e) => setCommentContent(e.target.value)}
                  ></TextField>
                  <Stack width="100%">
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        setComments([
                          ...comments,
                          {
                            name: "Omri Yacubovich",
                            time: "Just Now",
                            content: commentContent,
                          },
                        ]);

                        setCommentContent("");
                      }}
                    >
                      {"Send"}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Backdrop>
    </TableContainer>
  );
};
