import React from 'react';
import MaterialLayout from './components/Layout/MaterialLayout';
import DashboardPage from './components/Layout/DashboardPage';
import ApplicationForm from './components/ApplicationForm';
import Application from './components/ApplicationView';
import Applications from './components/ApplicationsView';
import Pipeline from './components/PipelineView';
import Products from './components/Products';
import Product from './components/Products/Product';
import AddProduct from './components/Products/AddProduct';
import SelectExchangeHostedProducts from './components/Products/SelectExchangeHostedProduct';
import ConfigureExchangeHostedProduct from './components/Products/ConfigureExchangeHostedProduct';
import { Box } from '@mui/material';



import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardPageWrapper component={<Products />}/>} />
        <Route path="/pipeline" element={<DashboardPageWrapper component={<Pipeline />}/>} />
        <Route path="/application" element={<DashboardPageWrapper component={<Application />}/>} />
        <Route path="/products" element={<DashboardPageWrapper component={<Products />}/>} />
        <Route path="/products/add-product" element={<DashboardPageWrapper component={<AddProduct />}/>}/>
        <Route path="/products/add-product/exchange-hosted/select" element={<DashboardPageWrapper component={<SelectExchangeHostedProducts />}/>} />
        <Route path="/products/add-product/exchange-hosted/configure" element={<DashboardPageWrapper component={<ConfigureExchangeHostedProduct />}/>} />
        <Route path="/products/business-term-loan" element={<DashboardPageWrapper component={<Product />}/>} />
        <Route path="/workflows" element={<DashboardPageWrapper component={<Pipeline />}/>}/>
        <Route path="/exchange" element={<DashboardPageWrapper component={<Pipeline />}/>}/>

        {/*OLD*/}
        <Route path="/onboarding" element={<ApplicationFormMaterial />} />
        <Route path="/applications" element={<DashboardPageWrapper component={<Applications />}/>}/>
      </Routes>
    </Router>
  );
}

function ApplicationFormMaterial() {
  return (
    <div>
      <MaterialLayout>
        <ApplicationFormView />
      </MaterialLayout>
    </div>
  );
}

function DashboardPageWrapper(props) {
  return (
    <div>
      <MaterialLayout lamaTheme={true}>
        {/* <DashboardPage> */}
        <Box sx={{backgroundColor:'#F7F9FA', display: 'flex', minHeight: '100vH'}}>    
          {props.component}
        </Box>
        {/* </DashboardPage> */}
      </MaterialLayout>
    </div>
  );
}
