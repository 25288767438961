import { Box, Tabs, Tab, Paper } from "@mui/material";

export const MainTabs = ({ selectedTab, onTabChange }) => (
  <Box sx={{ borderBottom: 1, borderColor: "#e0e0e0" }}>
    <Paper sx={{ bgcolor: "#f5f5f5", borderColor: "#e0e0e0" }} elevation={0}>
      <Tabs
        value={selectedTab}
        onChange={(e, v) => onTabChange(v)}
        sx={{ width: "100%" }}
        variant="fullWidth"
      >
        <Tab
          sx={{
            bgcolor: selectedTab === 0 ? "white" : undefined,
            borderRight: 1,
            borderColor: "#e0e0e0",
          }}
          label="Application"
        />
        <Tab
          sx={{
            bgcolor: selectedTab === 1 ? "white" : undefined,
            borderRight: 1,
            borderColor: "#e0e0e0",
          }}
          label="Decisioning"
        />
        <Tab
          sx={{
            bgcolor: selectedTab === 2 ? "white" : undefined,
            borderRight: 1,
            borderColor: "#e0e0e0",
          }}
          label="Closing"
          disabled
        />
        <Tab
          sx={{ bgcolor: selectedTab === 3 ? "white" : undefined }}
          label="Off-Ramp"
          disabled
        />
      </Tabs>
    </Paper>
  </Box>
);
