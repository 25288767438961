import InsightsIcon from "@mui/icons-material/Insights";
import GoogleIcon from "@mui/icons-material/Google";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import ImageIcon from "@mui/icons-material/Image";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import FacebookIcon from "@mui/icons-material/Facebook";

import {
  ShopifyIcon,
  QuickbooksIcon,
  ExperianIcon,
  FreshbooksIcon,
  LexisNexisIcon,
  StripeIcon,
  PaypalIcon,
  AccountingIcon,
  BankIcon,
  CreditBureauIcon,
  PayrollIcon,
  PoliceIcon,
  PosIcon,
  SocialMediaIcon,
  StateRegistryIcon,
  TaxIcon,
  WebIcon,
} from "./CustomIcons";

export const values = {
  companyName: "Chicago Roofing Services",
  address: "3753 W Fullerton Ave",
  city: "Chicago",
  state: "IL",
  firstName: "Omri",
  lastName: "Yacubovich",
  businessType: ["Roofing Contractor", "General Contractor"],
  loanAmount: 500000,
  intendedUse: ["Construction", "Working Capital"],
  duration: "24-month",
  approvedAmount: 75000,
  warnings: ["Partial Approval"],
  apr: 5.35,
  collateralEligibility: 75000,
  email: "ask@chicagoroofingservicesinc.com"
};
export const createRow = function (attribute, value, details, sources, validations) {
  return { attribute, value, details, sources, validations };
};
const details = {
  trend: { label: "Trend", Icon: StackedBarChartIcon },
  growthPace: { label: "growthPace", Icon: InsightsIcon },
  google: { label: "google", Icon: GoogleIcon },
  Locations: { label: "Locations", Icon: LocationOnIcon },
  maps: { label: "maps", Icon: MapIcon },
  other: { label: "other", Icon: null },
  Images: { label: "images", Icon: ImageIcon },
  law: { label: "law", Icon: LocalPoliceIcon },
  facebook: { label: "facebook", Icon: FacebookIcon },
};

const sources = {
  accounting: { label: "Accounting", Icon: AccountingIcon },
  tax: { label: "Tax", Icon: TaxIcon },
  bank: { label: "Bank", Icon: BankIcon },
  commerce: { label: "Point of Sale", Icon: PosIcon },
  payroll: { label: "Payroll", Icon: PayrollIcon },
  bureaus: { label: "Credit Bureaus", Icon: CreditBureauIcon },
  stateRegistry: { label: "State Registry", Icon: StateRegistryIcon },
  online: { label: "Open Web", Icon: SocialMediaIcon },
  socialMedia: { label: "Social Media", Icon: WebIcon },
  police: { label: "Police data", Icon: PoliceIcon },

  shopify: { label: "Shopify", Icon: ShopifyIcon },
  quickbooks: { label: "Quickbooks", Icon: QuickbooksIcon },
  experian: { label: "Experian", Icon: ExperianIcon },
  freshbooks: { label: "Freshbooks", Icon: FreshbooksIcon },
  lexisNexis: { label: "Lexis Nexis", Icon: LexisNexisIcon },
  stripe: { label: "Stripe", Icon: StripeIcon },
  paypal: { label: "PayPal", Icon: PaypalIcon },
};
export const collectSource = [
  sources.tax,
  sources.bank,
  sources.freshbooks,
  sources.stateRegistry,
  sources.paypal,
  sources.online,
  sources.socialMedia,
  sources.experian,
];

const validations = {
  good: {
    label: "Positive upward trend indicating healthy growth",
    severity: 0,
  },
  general: { label: "Attention - closely watch this indicator", severity: 1 },
  mismatch: {
    label: "Invalid data cosnsiistency Accounting, POS or Tax return mismatch",
    severity: 2,
  },
  div: {
    label:
      "Indicator's current value significantly deviates from last 12 months values",
    severity: 3,
  },
  downYOY: {
    label: "Year over year analysis shown significant decline",
    severity: 3,
  },
  ratioalert: {
    label: "Calculated ratio falls under industry benchmark",
    severity: 4,
  },
};

// Financials
export const profitabilityAnalysis = [
  createRow(
    "Annual Revenue",
    "$2,680,000",
    details.trend,
    [sources.freshbooks, sources.tax, sources.paypal],
    [validations.mismatch, validations.good]
  ),
  createRow(
    "YTD Revenue",
    "$750,400",
    details.trend,
    [sources.freshbooks, sources.paypal],
    []
  ),
  createRow(
    "Seasonality",
    "Summer",
    details.trend,
    [sources.freshbooks, sources.paypal],
    []
  ),
  createRow(
    "Annual Profit",
    "$321,600",
    details.trend,
    [sources.freshbooks, sources.tax],
    []
  ),
  createRow("YTD Profit", "87,636", details.trend, [sources.freshbooks], []),
  createRow(
    "Gross Profit Margin",
    "33%",
    details.trend,
    [sources.freshbooks],
    [validations.good]
  ),
  createRow(
    "Gross Profit Margin - YTD",
    "29%",
    details.trend,
    [sources.freshbooks],
    [validations.div]
  ),
  createRow(
    "Net Profit Margin",
    "12%",
    details.trend,
    [sources.freshbooks],
    []
  ),
  createRow(
    "Net Profit Margin - YTD",
    "9%",
    details.trend,
    [sources.freshbooks],
    [validations.div]
  ),
  createRow(
    "Non operating Revenue - Annual",
    "$240,000",
    details.trend,
    [sources.freshbooks, sources.tax],
    []
  ),
];
export const liquidityAnalysis = [
  createRow(
    "Bank balance",
    "$90,000",
    details.trend,
    [sources.bank, sources.freshbooks],
    [validations.good]
  ),
  createRow(
    "Average bank balance",
    "$57,000",
    details.trend,
    [sources.bank, sources.freshbooks],
    []
  ),
  createRow(
    "Accounts Receivable",
    "$78,000",
    details.trend,
    [sources.freshbooks],
    [validations.div]
  ),
  createRow(
    "Average Accounts Receivable",
    "$38,000",
    details.trend,
    [sources.freshbooks],
    []
  ),
  createRow(
    "Working Capital Ratio",
    "1.6",
    details.trend,
    [sources.freshbooks],
    [validations.good]
  ),
  createRow(
    "Quick Ratio",
    "0.9",
    details.trend,
    [sources.freshbooks, sources.bank],
    [validations.ratioalert]
  ),
  createRow(
    "Cash flow to debt Ratio",
    "3",
    details.trend,
    [sources.bank],
    [validations.good]
  ),
  createRow(
    "Operating Cashflow to net sales",
    "1",
    details.trend,
    [sources.freshbooks, sources.bank],
    []
  ),
  createRow(
    "Free Cashflow",
    "$45,000",
    details.trend,
    [sources.freshbooks, sources.bank],
    []
  ),
  createRow(
    "Free Cashflow Ratio",
    "0.9",
    details.trend,
    [sources.freshbooks, sources.bank],
    []
  ),
];
export const operationalEfficiency = [
  createRow("No of Employees", "12", details.trend, [sources.payroll], []),
  createRow(
    "Average employee annual wages",
    "$52,000",
    details.trend,
    [sources.payroll],
    []
  ),
  createRow(
    "Revenue per employee  - Annual",
    "$223,333",
    details.trend,
    [sources.freshbooks],
    [validations.good]
  ),
  createRow(
    "Inventory turnover",
    "130 days",
    details.trend,
    [sources.freshbooks],
    [validations.div]
  ),
  createRow(
    "AR Turnover",
    "5.7",
    details.trend,
    [sources.freshbooks],
    [validations.div]
  ),
  createRow(
    "Repeat customers rate",
    "36%",
    details.trend,
    [sources.payroll],
    []
  ),
  createRow(
    "Sales by main line of business",
    "$2,250,000",
    details.trend,
    [sources.freshbooks],
    []
  ),
  createRow("Main Line of business growth", "-6%", details.trend, [], []),
  createRow(
    "Sales by secondary line of business",
    "$430,000",
    details.trend,
    [sources.freshbooks],
    []
  ),
  createRow(
    "Secondary main Line of business growth",
    "12%",
    details.trend,
    [],
    []
  ),
];
export const debtAnalysis = [
  createRow(
    "Accounts Payable",
    "$156,033",
    details.trend,
    [sources.freshbooks, sources.tax],
    [validations.div]
  ),
  createRow(
    "Average Accounts Payable",
    "$80,000",
    details.trend,
    [sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Liabilities - Short term",
    "$160,982",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Liabilities Long Term",
    "$309,980",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Credit Card debt",
    "$57,000",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Debt to Asset Ratio",
    "1.8",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    [validations.good]
  ),
  createRow(
    "Credit Score Business",
    "87",
    details.trend,
    [sources.experian],
    []
  ),
  createRow(
    "Risk score",
    "87",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Financial stability risk",
    "76",
    details.trend,
    [sources.experian, sources.freshbooks, sources.tax],
    []
  ),
  createRow("Total Liens", "$340,000", details.trend, [sources.experian], []),
  createRow(
    "UCC Filing summary",
    "$150,000",
    details.trend,
    [sources.experian],
    []
  ),
];
// Info
export const general = [
  createRow(
    "Time in Business",
    "3.5 years",
    null,
    [sources.stateRegistry, sources.tax],
    []
  ),
  createRow("Locations", "3", details.maps, [sources.online], []),
  createRow("Trade name", "Chicago Roofing", null, [sources.stateRegistry], []),
  createRow(
    "Areas served",
    "Greater Chicago",
    details.maps,
    [sources.online],
    []
  ),
  createRow("Images", "25", details.Images, [sources.online], []),
];
export const taxationAndLegal = [
  createRow(
    "Bankruptcy filing",
    "N/A",
    null,
    [sources.experian, sources.stateRegistry],
    []
  ),
  createRow("Tax Debt", "$20,000", null, [sources.freshbooks, sources.tax], []),
  createRow("Status of past filings", "2020", null, [sources.tax], []),
  createRow(
    "Legal suits",
    "0",
    null,
    [sources.freshbooks, sources.tax],
    [validations.good]
  ),
  createRow(
    "Judgment filling",
    "$15,000",
    null,
    [sources.freshbooks, sources.tax],
    []
  ),
];
export const web = [
  createRow("Website visits", "11,700", details.trend, [sources.online], []),
  createRow(
    "Top search term",
    "roof repair",
    details.trend,
    [sources.online],
    []
  ),
  createRow("Web Traffic geo", "IL", details.maps, [sources.online], []),
  createRow("Domain ownership", "since 2018", null, [sources.online], []),
  createRow("Domain name", "ChicagoRoofing.com", null, [sources.online], []),
];
export const social = [
  createRow("Average review", "3.5", details.google, [sources.online], []),
  createRow(
    "Number of reviews",
    "134",
    details.trend,
    [sources.freshbooks, sources.tax],
    []
  ),
  createRow(
    "Facebook page likes",
    "456",
    details.facebook,
    [sources.online],
    []
  ),
  createRow("Employees on LinkedIn", "N/A", null, [sources.online], []),
  createRow("Yelp reviews", "N/A", null, [sources.online], []),
];
export const local = [
  createRow("Business locations", "3", details.Locations, [sources.online], []),
  createRow(
    "Zip income average",
    "$67,000",
    details.Locations,
    [sources.online],
    []
  ),
  createRow(
    "Foot traffic stats",
    "Zone A",
    details.Locations,
    [sources.online],
    []
  ),
  createRow(
    "Competitors Density in Area",
    "Low",
    details.Locations,
    [sources.online],
    []
  ),
  createRow(
    "Populations Growth in Area",
    "Above Average",
    details.Locations,
    [sources.online],
    [validations.good]
  ),
  createRow(
    "Crime rate",
    "below Average",
    details.Locations,
    [sources.police, sources.online],
    []
  ),
];
// Owners
const createOwner = function (
  firstName,
  lastName,
  ssn,
  image,
  ownershipPercentage,
  ficoScore,
  grossAnnualIncome
) {
  return {
    firstName,
    lastName,
    ssn,
    image,
    ownershipPercentage,
    ficoScore,
    grossAnnualIncome,
  };
};
export const owners = [
  createOwner("John", "Doe", "594-05-7590", null, 40, 590, 120000),
  createOwner("Allan", "Doe", "394-55-7556", null, 20, 520, 40000),
  createOwner("Peter", "Smith", "394-45-4390", null, 20, 700, 154000),
  createOwner("Daviv", "Adams", "394-15-7512", null, 20, 650, 80000),
];