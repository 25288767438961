import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import { at } from 'lodash';
import PropTypes from 'prop-types';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectField(props) {
    const { label, options, ...rest } = props;
    const [field, meta] = useField({...props, multiple: true});
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;
    
    function _renderHelperText() {
        if (isError) {
        return <FormHelperText>{error}</FormHelperText>;
        }
    }


    return (
        <React.Fragment>
        <FormControl fullWidth>
            <InputLabel {...rest}>{label}</InputLabel>
            <Select
              {...field} {...rest} value={selectedValue ? selectedValue : []}
            //value={['gym', 'airport']}
            multiple
            input={<OutlinedInput label="Option" />}
            renderValue={(selected) => {
                return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                    return <Chip key={value} label={value} />
                })}
                </Box>
            }}
            MenuProps={MenuProps}
            >
            {options.map((name) => (
                <MenuItem
                key={name}
                value={[name]}
                >
                {name}
                </MenuItem>
            ))}
            </Select>
            {_renderHelperText()}
        </FormControl>
        </React.Fragment>
  );
}

MultipleSelectField.defaultProps = {
    data: []
};

MultipleSelectField.propTypes = {
    data: PropTypes.array.isRequired
};

export default MultipleSelectField;
  
