import React from 'react';
import { Paper, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Header from '../Header';
import Footer from '../Footer';

import { theme, useStyle, lamaTheme } from './styles';

export default function MaterialLayout(props) {
  const { children } = props;
  const classes = useStyle();

  const selectedTheme = props.lamaTheme ? lamaTheme : theme;

  //title
  document.title = selectedTheme.copy.title;

  //icon
  // var link = document.querySelector("link[rel~='icon']");
  // if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  // }
  // link.href = selectedTheme.logo.symbol;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={selectedTheme}>
        <CssBaseline />
        {/* <Header />
        // <div className={classes.root}>
        //   <Paper className={classes.paper}>{children}</Paper>
        // </div>
        <Footer /> */}
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
