import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const themeConfigs = {
  default: {
    palette: {
      primary: {
        main: '#004CB8' 
      },
     secondary: {
       main: '#F8C883'
      },
      text:{
        primary: "#44f4f",
        secondary: "#787878"
      }
    },
    logo: {
      main:'/logo.png',
      symbol: '/icon.png'
    },
    copy:{
      title:'Small Business Bank'
    },
    typography: {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
      button: {
        textTransform: "none"
      }
    },
  },
  bankOfAmerica: {
    palette: {
      primary: {
        main: '#012169'
      },
     secondary: {
       main: '#e31837'
      }
    },
    typography: {
      fontFamily: `"cnx-regular", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    logo: {
      main:'/brands/bank-of-america-logo.svg',
      symbol: '/brands/bank-of-america-symbol.svg'
    },
    copy:{
      title:'Bank of America'
    } 
  },
  usBank: {
    palette: {
      primary: {
        main: '#0c2074'
     },
     secondary: {
       main: '#c7254e'
     }
    },
    typography: {
      fontFamily: `"helvetica neue", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    logo: {
      main:'/brands/us-bank-logo.svg',
      symbol: '/brands/us-bank-symbol.svg'
    },
    copy:{
      title:'US Bank'
    } 
  }
};

const lamaThemeConfig = {
  palette: {
    primary: {
      main: '#714EF5',
      dark: '#3221C1',
      light: '#E6E0FF',
    },
    secondary: {
      main: '#79F5BA',
      dark: '#40C18A',
      light: '#DAFFEE',
    },
  },
  logo: {
    main:'/logos/LAMA-logo.png',
    symbol: '/icons/LAMA-icon.png'
  },
  copy:{
    title:'LAMA AI'
  },
  typography: {
    fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: "none"
    }
  },
  // components: {
  //   MuiSlider: {
  //     styleOverrides: {
  //       markLabel: {
  //         color: '#616262',
  //         transform: 'translateX(-100%)',
  //         '&[data-index="0"]': {
  //           transform: 'none',
  //         },
  //       },
  //     },
  //   },
  // }
};
let lamaTheme = createTheme(lamaThemeConfig);

const selectedThemeConfig = lamaTheme;

//theme
let theme = createTheme(themeConfigs.default, selectedThemeConfig);

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

export { theme, useStyle, lamaTheme };
