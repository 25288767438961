import React from "react";
import {
  Grid,
  Card,
  Typography,
  Stack,
  Button,
  Alert,
  Link,
  Box
} from "@mui/material";

import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";

import Trend from 'react-trend';
import { CopyBlock, atomOneLight } from "react-code-blocks";

const iframeText = "<iframe src='https://application.lamabanking.com/' title='SBB Apply for loan' />";

export default function Embed(props) {
  const theme = useTheme();
  const navigate = useNavigate();  

  const Section = function(props) {
    return (
    <Card>
      <Stack p={4} >
        <Typography variant="h5" mb={4} textAlign='left'>{props.title}</Typography>
        {props.children}
      </Stack>
    </Card>
    )
  }

  // TODO: LOS integrations

  return (
    <Stack spacing={2}>
      <Section title="Borrower Onboarding">
        <Grid container spacing={4}>
          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Link to App</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <Alert severity="info"><Link>https://application.lamabanking.com/</Link></Alert>
              <Button>Copy</Button>
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Embedded iFrame</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <CopyBlock
                text={iframeText}
                language={'jsx'}
                showLineNumbers={false}
                theme={atomOneLight}
                wrapLines
              />
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>REST API</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <Alert severity="info"><Link>https://api.lama.ai/v1/onboard/</Link></Alert>
              <Button>Open Swagger Documentation</Button>
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Monitor</Typography>
          </Grid>
          <Grid item container xs={9} spacing={2}>
            <Grid item xs={4}>
              <Box>
              <Trend
                smooth
                autoDraw
                autoDrawDuration={3000}
                autoDrawEasing="ease-out"
                data={[0,2,5,9,5,10,3,5,0,0,1,8,2,9,4]}
                gradient={['#f72047', '#1feaea']}
                radius={10}
                strokeWidth={2}
                strokeLinecap={'butt'}
              /></Box>
              </Grid>
              <Grid item xs={8} alignSelf='center'>
              <Button>Expand Statistics</Button>
              </Grid>
            
          </Grid>
        </Grid>
      </Section>

      <Section title="Decisioning">
        <Grid container spacing={4}>
          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Link to App</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <Alert severity="info"><Link>https://sbb.lama.ai/</Link></Alert>
              <Button>Copy</Button>
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>REST API</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <Alert severity="info"><Link>https://api.lama.ai/v1/decisioning/</Link></Alert>
              <Button>Open Swagger Documentation</Button>
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Monitor</Typography>
          </Grid>
          <Grid item container xs={9} spacing={2}>
            <Grid item xs={4}>
              <Box>
              <Trend
                smooth
                autoDraw
                autoDrawDuration={3000}
                autoDrawEasing="ease-out"
                data={[0,1,2,5,6,7,6,4,2,4,5,8,6,9,8]}
                gradient={['#f72047', '#1feaea']}
                radius={10}
                strokeWidth={2}
                strokeLinecap={'butt'}
              /></Box>
              </Grid>
              <Grid item xs={8} alignSelf='center'>
              <Button>Expand Statistics</Button>
              </Grid>
            
          </Grid>
        </Grid>
      </Section>


      <Section title="Exchange">
        <Grid container spacing={4}>
          
          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>REST API</Typography>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" spacing={2}>
              <Alert severity="info"><Link>https://api.lama.ai/v1/exchange/</Link></Alert>
              <Button>Open Swagger Documentation</Button>
            </Stack>
          </Grid>

          <Grid item xs={3} alignSelf='center'>
            <Typography variant="body2" textAlign={'left'}>Monitor</Typography>
          </Grid>
          <Grid item container xs={9} spacing={2}>
            <Grid item xs={4}>
              <Box>
              <Trend
                smooth
                autoDraw
                autoDrawDuration={3000}
                autoDrawEasing="ease-out"
                data={[0,0,0,2,5,7,4,9,7,8,8,7,6,7,8]}
                gradient={['#f72047', '#1feaea']}
                radius={10}
                strokeWidth={2}
                strokeLinecap={'butt'}
              /></Box>
              </Grid>
              <Grid item xs={8} alignSelf='center'>
              <Button>Expand Statistics</Button>
              </Grid>
            
          </Grid>
        </Grid>
      </Section>

    </Stack>
  );
}
