import React from 'react';
import {Grid, Card, Button, Typography, Stack, Chip, SvgIcon, 
    Box, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@mui/material';
import { borderRight } from '@mui/system';

function intToString (value) {
    var suffixes = ["", "K", "M", "B","T"];
    var suffixNum = Math.floor((""+value).length/3);
    var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(2));
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue+suffixes[suffixNum];
}

export default function PipelineTab(props) {
    const selected = props.selectedTab === props.tab.name;
    const sum = props.tab.applications.map(app => app.amount).reduce((partialSum, a) => partialSum + a, 0);
    const sumFormatted = '$' + intToString(sum);

    return <Stack width='100%' borderBottom={selected ? 0 : 2 } sx={{cursor:"pointer"}} borderRight={2} borderColor='#E0E0E0'
        bgcolor={selected ? 'white' : 'grey.100'}
        onClick={() => props.setSelected(props.tab.name)}>
        <Box p={2} >
        <Typography variant='body1' color={selected ? 'textPrimary' : 'textSecondary'}>{props.tab.name}</Typography>
        <Stack direction={'row'} spacing={1}>
            <Typography variant='h4' color={selected ? 'textPrimary' : 'textSecondary'}>{sumFormatted}</Typography>
            <Typography variant='h4' color='textSecondary'>({props.tab.applications.length})</Typography>
        </Stack>
        </Box>
        {selected ? <Divider sx={{ borderBottomWidth: 5 }} color='primary.main' size={2}/> : null}
    </Stack>
}