import React from 'react';
import { Grid, Typography, Container, Stack, Alert } from '@mui/material';
import { InputField, CheckboxField } from '../../FormFields';
import * as Yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';

const fields = {
  firstName: {
    name: 'firstName',
    label: 'First Name*',
    validation: Yup.string().required('First name is required'),

  },
  lastName: {
    name: 'lastName',
    label: 'Last Name*',
    validation: Yup.string().required('Last name is required'),

  },
  email: {
    name: 'email',
    label: 'Email Address*',
    validation: Yup.string().email('Invalid email format').required('Email address is required'),
  },
  ssn: {
    name: 'ssn',
    label: 'SSN'
  },
  onBehalfOfOwner: {
    name: 'onBehalfOfOwner',
    label: "I'm applying on behalf of the owner(s)"
  }
};

const View = function() {
  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{width:'600px'}}>
        <Grid item xs={12} sm={6}>
          <InputField name={fields.firstName.name} label={fields.firstName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={fields.lastName.name} label={fields.lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={fields.email.name} label={fields.email.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={fields.ssn.name} label={fields.ssn.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name={fields.onBehalfOfOwner.name}
            label={fields.onBehalfOfOwner.label}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default {
  View,
  title: 'Please enter your personal info',
  caption: "Let's get to know each other",
  fields
};
