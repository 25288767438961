import React from 'react';
import { Grid, Typography } from '@mui/material';
import { InputField, DatePickerField, RadioGroupField } from '../../FormFields';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';

const fields = {
  isRegistered: {
    name: 'isRegistered',
    label: 'Registered',
    validation: Yup.string().required('Please select an option')
  }
}

const yesNo = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' }
];

let bankName = null;

const View = function() {
  const theme = useTheme();
  bankName = theme.copy.title;
  return (
    <React.Fragment>
      <RadioGroupField
        name={fields.isRegistered.name}
        label="" //{isRegistered.label}
        options={yesNo}
        fullWidth
      />
    </React.Fragment>
  );
};

export default {
  caption: "Hey! I'm Emma",
  title: `Do you have an account with ${bankName || 'us'}?`, //global state doesn't work with react :\
  View,
  fields
};
