import React from "react";
import {
  Grid,
  Card,
  Typography,
  Stack,
  Button
} from "@mui/material";

import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";
import { ReactComponent as RuleOnIcon } from '../../../assets/icons/rule-on.svg';
import { ReactComponent as RuleOffIcon } from '../../../assets/icons/rule-off.svg';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import EditRule from "./EditRule";


function Attribute(props) {
  return (
    <Stack direction="row" alignItems="center" borderRadius={'4px'} bgcolor={props.color} py={'4px'} px={1} spacing={1}>
      {props.icon ? <props.icon style={{ color: 'gray' }} /> : null}
      <Typography variant="body2" color="text.secondary">{props.name}</Typography>
    </Stack>
  )
}

export function Rule(props) {
  return ( <Stack direction="row" spacing={2} alignItems="center">
    {props.rules.map((innerRule, index) => {
      return <Stack direction="row" spacing={2} alignItems="center" key={index}>
        <Attribute color="#FCEEE1" {...innerRule.attribute}/>
        <Attribute color="#D8EDFC" name={innerRule.operator}/>
        <Attribute color="#F3EFFF" name={innerRule.value+(innerRule.attribute.suffix || '')}/>
        {index < props.rules.length - 1 && <Typography>OR</Typography>}
      </Stack>
    })}
  </Stack>
  )
}

export default function Rules(props) {
  const theme = useTheme();
  const navigate = useNavigate();  

  const [selectedRule, setSelectedRule] = React.useState(null);

  const handleClickEditRule = (rule) => {
    setSelectedRule(rule);
  };

  const handleCloseEditRule = (rule) => {
    setSelectedRule(null);
  };

  return (
    <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h6">
            {props.title}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button startIcon={<AddIcon/>} variant="outlined">New</Button>
          </Stack>
        </Stack>

        <Stack spacing={2}>
        {props.rules.map((rule, index) => (
          <Card sx={{ height: "100%", borderRadius: "5px", p:2 }} elevation={0}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">

              <Grid item xs={8}>
                <Stack direction="row" spacing={2} alignItems="center">
                {rule.active? <RuleOnIcon/> : <RuleOffIcon/>}
                <Rule rules={[rule]}/>
                </Stack>
              </Grid>
              {rule.target &&
              <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                {rule.target.icon}
                <Typography>{rule.target.name}</Typography>
                </Stack>
              </Grid>}
              <Button onClick={() => handleClickEditRule(rule)}>
                <EditIcon/>
              </Button>
            </Grid>
          </Card>
        ))}
        </Stack>
      <EditRule
        rule={selectedRule}
        open={!!selectedRule}
        onClose={handleCloseEditRule}
      />
    </Stack>
  );
}
