import React from 'react';
import {Paper, Grid, Card, Button, Typography, Avatar, Stack, Chip, Container, Tooltip, Alert, AppBar, SvgIcon,
    Toolbar, IconButton, Box, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Menu, Badge } from '@mui/material';
import TimelineDot from '@mui/lab/TimelineDot';
import DashboardMenu from './../Menu/';

import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';


import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PaidIcon from '@mui/icons-material/Paid';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LandscapeIcon from '@mui/icons-material/Landscape';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { ReactComponent as DotGreenIcon } from '../../assets/icons/dot-green.svg';
import { ReactComponent as DotBlueIcon } from '../../assets/icons/dot-blue.svg';
import { ReactComponent as DotOrangeIcon } from '../../assets/icons/dot-orange.svg';

import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';


// Business Types Icons
const businessTypeIcons = {
    medical: {label: 'Medical', icon: <MedicalServicesIcon/>},
    landscaping: {label: 'Lanscaping', icon: <LandscapeIcon/>},
    restaurant: {label: 'Restaurant', icon: <FastfoodIcon/>},
    store: {label: 'Convenience Store', icon: <LocalConvenienceStoreIcon/>},
    freight: {label: 'Freight', icon: <LocalShippingIcon/>},
    realEstate: {label: 'Real Estate', icon: <AddBusinessIcon/>},
    accounting: {label: 'Accounting', icon: <PaidIcon/>},
}

// Owners
const createApplication = function(source, businessType, loanType, amount, useOfFunds, maxRate, riskScore, fastApprovalGuarantee, lamaCollateral, traditionalCollateral, businessLocation, craLoan) {
    return { source, businessType, loanType, amount, useOfFunds, maxRate, riskScore, fastApprovalGuarantee, lamaCollateral, traditionalCollateral, businessLocation, craLoan };
}

const applications = [
    createApplication('First Bank of Omaha',businessTypeIcons.medical,'SBA 7(a) Business Loan',"$250,000",'Working Capital','10%','690','','90%','-','Nebraska','Yes'),
    createApplication('Uber Freight',businessTypeIcons.freight,'Equipment Financing',"$550,000",'Commercial Vehicles','6%','750','Yes','100%','-','California','No'),
    createApplication('Square Capital', businessTypeIcons.restaurant,'Equipment Financing',"$100,000",'Restaurant Equipment','10%','820','','60%','-','Kansas','Yes'),
    createApplication('John Hill Financing',businessTypeIcons.landscaping,'SBA Express',"$300,000",'Short-Term','18%','620','','70%','-','Nebraska','Yes'),
    createApplication('Nebraska Commercial Bank',businessTypeIcons.realEstate,'CRE (Participation)',"$6,250,000",'Hotel Financing','11%','740','','0%','Property','Nebraska','No'),
    createApplication('GrubHub Capital',businessTypeIcons.restaurant,'Line of Credit',"$75,000",'Inventory Financing','16%','700','Yes','75%','-','New York','No'),
    createApplication('MedDevice Finance',businessTypeIcons.medical,'Equipment Financing (Participation)',"$2,500,000",'Medical Equipment','7%','810','Yes','100%','-','Nebraska','No'),
    createApplication('Mountain Bank',businessTypeIcons.store,'Line of Credit',"$85,000",'Inventory Financing','15%','670','Yes','90%','-','Colorado','Yes'),
    createApplication('Dallas Savings Bank',businessTypeIcons.realEstate,'CRE (Participation)',"$8,000,000",'Property Development','8%','790','','0%','Property','Texas','No'),
    createApplication('Savings Bank of Omaha', businessTypeIcons.medical,'Practice Financing',"$150,000",'Practice Acquisition','12%','590','','0%','Personal Residence','Nebraska','No'),
    createApplication('National Commercial Bank', businessTypeIcons.accounting,'Term Loan',"$250,000",'Expansion','7%','800','','70%','-','New York','Yes')
]

export default function ApplicationsView() {
    const [selectedTab, setTabValue] = React.useState(2);
    const theme = useTheme();

    const Layout = function() {
        return <Grid container spacing={4} sx={{p:4, overflowX:'auto'}}>
                <Grid item xs={12} >
                    <Card sx={{px:6, pt:3, height: '100%', borderRadius:'15px'}} elevation={0}>
                        <Filters/>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{p:6, height: '100%', borderRadius:'15px'}} elevation={0}>
                        <SourcesSummary/>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{p:6, height: '100%', borderRadius:'15px', backgroundColor: 'secondary.main'}} elevation={0}>
                        <ApprovalsSummary/>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{p:6, height: '100%', borderRadius:'15px'}} elevation={0}>
                        <Flow/>
                    </Card>
                </Grid>
            </Grid>
    }

    const Filters = () => {
        return <Box>
             <Stack direction="row">
                <Typography variant='h4' color='textSecondary'>Dashboard</Typography>
                <Button sx={{mx:1}}><RefreshIcon fontSize='medium'/></Button>
                <Typography variant='body2' color='textSecondary' alignSelf='center'>Last updated April 21, 2022 12:23</Typography>
            </Stack>
            <Box sx={{ mt: 1 }}>
                <Tabs value={selectedTab} onChange={(event, newValue) => setTabValue(newValue)}>
                    <Tab label="Today" />
                    <Tab label="This Week" />
                    <Tab label="This Month" />
                    <Tab label="This Year" />
                </Tabs>
            </Box>
        </Box>
    }

    

    const SourcesSummary = () => {
        return <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant='h6'>Applications Flow</Typography>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{ width: '100%', maxWidth:'128px' }} component="img" src="/images/flow-legend.png"/>
            </Grid>
            <Grid item xs={6} sx={{alignSelf:'center'}}>
                <Box sx={{ width: '100%', maxWidth:'254px' }} component="img" src="/images/flow-graph.png"/>
            </Grid>
        </Grid>
    }

    const ApprovalsSummary = () => {
        return <Box>
            <Typography variant='h6'>Approvals</Typography>
            <Grid container spacing={4} sx={{mt:1}}>
                <Grid item xs={6}>
                    <Stack direction="column" justifyContent="center" height='100%'>
                        <Stack direction="row" >
                            <Typography variant='h2' sx={{fontWeight: 500}}>118</Typography>
                            <Typography variant='body1' sx={{alignSelf:'flex-end'}}> /212</Typography>
                        </Stack>
                        <Typography variant='body2' color='textSecondary'>Applications</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '100%', maxWidth:'235px' }} component="img" src="/images/approvals-graph.png"/>
                </Grid>
                <Grid item xs={4}>
                <Stack direction='row' spacing={4}>
                    <Stack direction='column'>
                        <Typography color='primary' width='max-content' sx={{fontWeight: 500}}>Internal Funding</Typography>
                        <Stack direction='row' spacing={2} sx={{alignItems: 'center'}}>
                            <SvgIcon component={DotGreenIcon} inheritViewBox sx={{color: 'secondary.main', fontSize:14}} />
                            <Typography>84</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='column'>
                        <Typography color='#3D77EB' width='max-content' sx={{fontWeight: 500}}>Participation</Typography>
                        <Stack direction='row' spacing={2} sx={{alignItems: 'center'}}>
                            <SvgIcon component={DotBlueIcon} inheritViewBox sx={{color: 'secondary.main', fontSize:14}} />
                            <Typography>4</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='column'>
                        <Typography color='#FFAD38' width='max-content' sx={{fontWeight: 500}}>Network Funded</Typography>
                        <Stack direction='row' spacing={2} sx={{alignItems: 'center'}}>
                            <SvgIcon component={DotOrangeIcon} inheritViewBox sx={{color: 'secondary.main', fontSize:14}} />
                            <Typography>30</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                </Grid>
                {/* <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                        <CheckCircle color='success'/>
                        <Typography color='textSecondary'>Approval Rate: 56% (118)</Typography>
                    </Stack>
                </Grid> */}
            </Grid>

        </Box>
    }

    const Flow = () => {
        const alignText = 'left';

        return <Box>
        <Typography variant='h6'>Applications Flow</Typography>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={alignText}>Source</TableCell>
              <TableCell align={alignText}>Business Type</TableCell>
              <TableCell align={alignText}>Loan Type</TableCell>
              <TableCell align={alignText}>Amount</TableCell>
              <TableCell align={alignText}>Use of Funds</TableCell>
              <TableCell align={alignText}>Max Rate</TableCell>
              <TableCell align={alignText}>Risk Score</TableCell>
              <TableCell align={alignText}>Fast Approval Guarantee</TableCell>
              <TableCell align={alignText}>LAMA Collateral</TableCell>
              <TableCell align={alignText}>Traditional Collateral</TableCell>
              <TableCell align={alignText}>Business Location</TableCell>
              <TableCell align={alignText}>CRA Loan</TableCell>
              <TableCell align={alignText}>Link to Application</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((row) => (
              <TableRow
                key={row.title}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align={alignText}>{row.source}</TableCell>
                <TableCell align={alignText}><Chip sx={{px:1}} icon={row.businessType.icon} label={row.businessType.label} color='primary' variant='outlined'/></TableCell>
                <TableCell align={alignText}>{row.loanType}</TableCell>
                <TableCell align={alignText}>{row.amount}</TableCell>
                <TableCell align={alignText}><Chip label={row.useOfFunds} color='primary' variant='outlined'/></TableCell>
                <TableCell align={alignText}>{row.maxRate}</TableCell>
                <TableCell align={alignText}>{row.riskScore}</TableCell>
                <TableCell align={alignText}>{row.fastApprovalGuarantee == 'Yes' ? <CheckCircleOutline color='success'/> : <ErrorOutline color='warning'/>}</TableCell>
                <TableCell align={alignText}>{row.lamaCollateral}</TableCell>
                <TableCell align={alignText}>{row.traditionalCollateral}</TableCell>
                <TableCell align={alignText}>{row.businessLocation}</TableCell>
                <TableCell align={alignText}>{row.craLoan == 'Yes' ? <CheckCircleOutline color='success'/> : <ErrorOutline color='warning'/>}</TableCell>
                <TableCell align={alignText}><Button><SvgIcon component={LinkIcon} inheritViewBox sx={{color: 'secondary.main'}} size='large' /></Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>

        </Box>
    }

    const getField = function(title, value, extra) {
        return (
            <Grid item xs={6}>
                <Typography variant="body1">{title}</Typography>
                <Typography variant="h3" color='textSecondary'>{value}</Typography>
                {extra}
            </Grid>
        )
    }

    const getChips = function(values, props) {
        return <Grid container spacing={1} sx={{mt:1}}>
            {values.map((value, index) =><Grid item  key={index}> <Chip label={value} {...props} /> </Grid>)}
        </Grid>
    }

    return <Layout/>;
}