import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/styles";

import {
  Typography,
  Stack,
  Box,
  Tab,
  Tabs
} from "@mui/material";

import ApplicationRequirements from "./tabs/ApplicationRequirements";
import Rules from "./tabs/Rules";
import AutomationRules from "./tabs/AutomationRules";
import Integrations from "./tabs/Integrations";
import Embed from "./tabs/Embed";
import {TermLoan} from "./templates/TermLoan";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Product() {
  const theme = useTheme();
  const navigate = useNavigate();  
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Stack spacing={2} p={4}  width="100%" >
      <Stack direction="row" spacing={1}>
      <Typography variant="body1" color="textSecondary">
        API Portal
      </Typography>
      <ChevronRightIcon/>
      <Typography variant="body1" color="textSecondary">
        Business Term Loan
      </Typography>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' } }>
        <Tabs value={selectedTab} onChange={handleChange} sx={{ width: "100%" }} variant="fullWidth">
          <Tab label="Application" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 0 ? "white" : undefined}}/>
          <Tab label="Knockout Attributes" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 1 ? "white" : undefined}}/>
          <Tab label="Automation Rules" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 2 ? "white" : undefined}}/>
          <Tab label="Capacity Partners" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 3 ? "white" : undefined}}/>
          {/* <Tab label="Inbound Appetite" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 4 ? "white" : undefined}}/> */}
          <Tab label="Integrations" sx={{borderRight: 1, borderColor: "#e0e0e0", bgcolor: selectedTab === 4 ? "white" : undefined}} />
          <Tab label="Embed" sx={{bgcolor: selectedTab === 5 ? "white" : undefined}} />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} index={0}>
        <ApplicationRequirements requirementsGroups={TermLoan.requirementsGroups}/>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <Rules rules={TermLoan.knockoutRules} title="Divert applications that meet any of the following criteria"/>
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
          <AutomationRules rules={TermLoan.automationRules}/>
      </TabPanel>

      <TabPanel value={selectedTab} index={3}>
        <Rules rules={TermLoan.offrampRestrictions} title=""/>
      </TabPanel>

      {/* <TabPanel value={selectedTab} index={4}>
        <Rules rules={TermLoan.inboundAppetite} title=""/>
      </TabPanel> */}

      <TabPanel value={selectedTab} index={4}>
        <Integrations/>
      </TabPanel>

      <TabPanel value={selectedTab} index={5}>
        <Embed/>
      </TabPanel>

    </Stack>
  );
}
